import React, { useEffect, useState, useContext } from 'react'
import { Header, Footer, Spinner, NoInternet } from '../shared';
import { FaTwitter } from 'react-icons/fa';
import { BsFacebook, BsLinkedin } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { NewsItem } from './Landing'
import { useLocation } from 'react-router-dom';
// import NewsData from '../shared/NewsData.json';
import { AppDataContext } from '../Contexts/AppDataContext'
import { useParams } from "react-router-dom";
import _ from 'lodash'
import News from '../Landing/News';
import { loadLandingData } from '../shared/loadData';



const itemStructure = {
    id: "",
    header: "",
    summary: "",
    content: [],
    tags: [],
    date: "",
};


function ReadBlog(props) {

    let { id } = useParams();


    const location = useLocation()
    const { pageData, setPageData } = useContext(AppDataContext)


    const [blogItem, setBlogItem] = useState(itemStructure)
    const [randoms, setRandoms] = useState([])

    const [showSpinner, setShowSpinner] = useState(false)
    const [noWifi, setNoWifi] = useState(false)
    const [allData, setAllData] = useState([])

    useEffect(() => {

        async function fetchData() {
            const result = await loadLandingData(setAllData, setShowSpinner, setNoWifi, 1)

            const data = result.newsData.find(x => x.id === id)
            const NewsIndex = result.newsData.findIndex(x => x.id === id);


            const generatedVals = generateRandom(NewsIndex, result.newsData.length)

            setPageData(result)

            setRandoms(generatedVals)
            setBlogItem(data)
        }

        if (_.isEmpty(pageData)) {
            setShowSpinner(true)
            fetchData()
        } else {

            const data = pageData.newsData.find(x => x.id === id)
            const NewsIndex = pageData.newsData.findIndex(x => x.id === id);


            const generatedVals = generateRandom(NewsIndex, pageData.newsData.length)

            setRandoms(generatedVals)
            setBlogItem(data)
        }




        window.scrollTo({ top: 0, behavior: 'smooth' });

    }, [id])




    // console.log(blogItem)

    return (
        showSpinner ? <Spinner showSpinner={showSpinner} /> :

          noWifi ? <NoInternet /> :
        <>
            <Header />
            <div className='readblog'>

                <div className="readblog__tags">

                    {
                        blogItem.tags.map((item, index) => (
                            <p className="readblog__tags__item">{item}</p>
                        ))
                    }


                </div>

                <h2 className="readblog__title">{blogItem.header}</h2>
                <p className="readblog__summary">{blogItem.summary}</p>

                <div className="readblog__imgbox">
                    <img src={blogItem.imgurl} alt="Representation of Blog in visual format" className="readblog__img" />
                </div>

                <div className="readblog__text">
                    {
                        blogItem.content.map((item, index) => (
                            <div className="readblog__text__container">
                                <h3 className="readblog__text__head">{item.section}</h3>
                                <p className="readblog__text__content">{item.text}</p>
                            </div>
                        ))
                    }
                </div>

                <div className="readblog__share">
                    <h4 className="readblog__share__header">Share Article</h4>
                    <div className="readblog__share__iconbox">
                        <FaTwitter className='readblog__share__icon' />
                        <BsFacebook className='readblog__share__icon' />
                        <BsLinkedin className='readblog__share__icon' />
                        <AiFillInstagram className='readblog__share__icon' />
                    </div>
                </div>

                <div className="readblog__articles">
                    <div className="readblog__articles__header">
                        <h3 className="readblog__articles__header__main">Related Articles</h3>
                        <p className="readblog__articles__header__sub">All Articles &rarr;</p>
                    </div>

                    <div className="news__body__sub news__body__sub__centered">

                        {
                            randoms.map((item, index) => (
                                <NewsItem
                                    data={_.isEmpty(pageData) ? allData.newsData[item] : pageData.newsData[item]}
                                    index={item}
                                    size={_.isEmpty(pageData) ? allData.newsData.length : pageData.newsData.length}
                                />
                            ))
                        }

                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default ReadBlog;

const generateRandom = (index, size) => {
    let randoms = [];
    let randNum;

    while (randoms.length < 3) {
        randNum = Math.floor(Math.random() * size);

        while (randoms.includes(randNum) || randNum === index) {
            // console.log(randNum + '<---- randomNumber  ' + index + '<-------index')
            randNum = Math.floor(Math.random() * size);
        }
        randoms.push(randNum)

    }


    return randoms;
}