import React from 'react'
import NoPlayer from '../img/NoPlayer.png'

function SinglePlayerLanding(props) {
    
    // console.log(props)

  return (
    <div className='singleplayer__landing'>
        <div className="singleplayer__landing__content">
            <div className="singleplayer__landing__content__left">
                <div className="singleplayer__landing__content__left__details">
                    <div className="singleplayer__landing__content__left__details__number">{props.data.jerseynumber}</div>
                    <div className="singleplayer__landing__content__left__details__name">
                        <span>{(props.data.fullname).split(' ')[0]}</span>
                        <span>{(props.data.fullname).split(' ')[1]}</span>
                    </div>
                </div>
                <div className="singleplayer__landing__content__left__position">{props.data.role}</div>

            </div>

            <div className="singleplayer__landing__content__right">
                <img src={props.data.imgurl === "" ? NoPlayer : props.data.imgurl} alt="Player Rep" />
            </div>
        </div>
    </div>
  )
}

export default SinglePlayerLanding