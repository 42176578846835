import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import { BsTrophy } from 'react-icons/bs';
import CountdownTimer from './Countdown';
import { GiTrophy } from 'react-icons/gi'

// import TeamData from '../shared/TeamData.json';
// import MatchData from '../shared/AllMatchData.json';

let playedIndex = 12;
let upcomingIndex = 13;

var months = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];

const days = [
    "Sunday", "Monday", "Tuesday", "Wednesday",
    "Thursday", "Friday", "Saturday"
]

// const nextGameData = props.data.matches[currentIndex+1]
// console.log(nextGameData)


function Matches(props) {

    const navigate = useNavigate('')
    const [tableData, setTableData] = useState(props.data.teams.slice(0, 6))

    const [data, setData] = useState(props.data.matches[playedIndex])
    const [nextData, setNextData] = useState(props.data.matches[upcomingIndex])

    useEffect(() => {
        // Filter out teams belonging to this season only..
        const filteredTeams = props.data.teams.filter(team => team.season === props.data.seasons[props.data.seasons.length - 1].display)
        // console.log(props.data.matches)
        setSpotlightData()
       
        const SortedData = sortTeams(filteredTeams)
        setTableData(SortedData.slice(0, 6))

    }, [])

    // console.log(data)
    // console.log(nextData)

    function setSpotlightData() {
        // console.log(props.data)
        const data = props.data.settings[0]
        const lastGame = props.data.matches.find(match => match.fsID === data.set_last_game)
        const upcomingGame = props.data.matches.find(match => match.fsID === data.set_upcoming_game)
        setData(lastGame)
        setNextData(upcomingGame)
        // console.log('upcoming & last games')
        // console.log(lastGame)
        // console.log(upcomingGame)
        
    }


    return (

        _.isEmpty(data) ? <div /> :

            <div className='matches'>
                <div className="matches__header">
                    <div className="matches__header__main">
                        <h4 className="matches__header__title">Matches</h4>
                        <div className="matches__header__box">
                            <p>First Team</p>
                            {
                                /*
                                    <p>Women's Team</p>
                                    <p>Under 21</p>
                                    <p>Under 17</p>
                                */
                            }

                        </div>
                    </div>
                    <div className="matches__header__btnbox">
                        <button
                            className="matches__header__btn btn"
                            onClick={() => navigate('/fixtures')}
                        >
                            All Fixtures
                        </button>
                    </div>
                </div>

                <div className="matches__container">

                    <div className="matches__played">

                        <div className="matches__played__head">
                            <div className="matches__played__head__datebox">
                                <p className="matches__played__head__dayofmonth">{(new Date(data.date)).getDate()}</p>
                                <div className="matches__played__head__monthbox">
                                    <p className="matches__played__head__day">{days[(new Date(data.date)).getDay()]}</p>
                                    <p className="matches__played__head__month">{months[(new Date(data.date)).getMonth()]}</p>
                                </div>

                            </div>
                            <div className="matches__played__head__title">
                                Last Result
                            </div>
                        </div>

                        <div className="matches__played__body">

                            <div className="matches__played__body__team">
                                <img
                                    src={data.type ? props.data.teams[getTeamIndex('nsoatreman', props.data.teams)].logo : props.data.teams[getTeamIndex(data.team.split(' ')[0], props.data.teams)].logo}
                                    alt="My Team Logo"
                                    className="matches__played__body__team__logo"
                                />
                                <p>{data.type ? props.data.teams[getTeamIndex('nsoatreman', props.data.teams)].name : props.data.teams[getTeamIndex(data.team.split(' ')[0], props.data.teams)].name}</p>
                            </div>

                            <div className="matches__played__body__result">
                                <div className="matches__played__body__result__trophy">
                                    <BsTrophy className='matches__played__body__result__trophy__icon' />
                                    <p>{data.league}</p>
                                </div>

                                <div className="matches__played__body__result__score">
                                    <span className="matches__played__body__result__score__myteam">
                                        {data.home_team.goals.length}
                                    </span>
                                    <span className="matches__played__body__result__score__colon">:</span>
                                    <span className="matches__played__body__result__score__otherteam">
                                        {data.away_team.goals.length}
                                    </span>
                                </div>

                                <div className="matches__played__body__result__stadium">
                                    {data.type ? 'Koramansah Park' : 'Away Park'}
                                </div>

                            </div>

                            <div className="matches__played__body__team">
                                <img
                                    src={!data.type ? props.data.teams[getTeamIndex('nsoatreman', props.data.teams)].logo : props.data.teams[getTeamIndex(data.team.split(' ')[0], props.data.teams)].logo}
                                    alt="other Team Logo"
                                    className="matches__played__body__team__logo"
                                />
                                <p>{!data.type ? props.data.teams[getTeamIndex('nsoatreman', props.data.teams)].name : props.data.teams[getTeamIndex(data.team.split(' ')[0], props.data.teams)].name}</p>
                            </div>

                        </div>

                        <div className="matches__played__btnbox">
                            <button
                                className="matches__played__btn btn"
                                onClick={() => window.open('https://www.youtube.com/@Nsoatreman_fc/videos')}
                            >
                                Highlights
                            </button>
                            <button
                                className="matches__played__btn btn"
                                onClick={() => navigate(`/matchdetails/${data.id}`)}
                            >
                                Match Report
                            </button>
                        </div>
                    </div>

                    <div className="matches__upcoming">
                        <div className="matches__played__head">
                            <div className="matches__played__head__datebox">
                                <p className="matches__played__head__dayofmonth">{(new Date(nextData.date)).getDate()}</p>
                                <div className="matches__played__head__monthbox">
                                    <p className="matches__played__head__day">{days[(new Date(nextData.date)).getDay()]}</p>
                                    <p className="matches__played__head__month">{months[(new Date(nextData.date)).getMonth()]}</p>
                                </div>

                            </div>
                            <div className="matches__played__head__title">
                                Last Result
                            </div>
                        </div>

                        <div className="matches__played__body">

                            <div className="matches__played__body__team">
                                <img
                                    src={nextData.type ? props.data.teams[getTeamIndex('nsoatreman', props.data.teams)].logo : props.data.teams[getTeamIndex(nextData.team.split(' ')[0], props.data.teams)].logo}
                                    alt="My Team Logo"
                                    className="matches__played__body__team__logo"
                                />
                                <p>{nextData.type ? props.data.teams[getTeamIndex('nsoatreman', props.data.teams)].name : props.data.teams[getTeamIndex(nextData.team.split(' ')[0], props.data.teams)].name}</p>
                            </div>

                            <div className="matches__played__body__result">
                                <div className="matches__played__body__result__trophy">
                                    <BsTrophy className='matches__played__body__result__trophy__icon' />
                                    <p>{nextData.league}</p>
                                </div>
                                <div className="matches__upcoming__body">
                                    <CountdownTimer date={nextData.date}/>
                                </div>

                                <div className="matches__played__body__result__stadium">
                                    {nextData.type ? 'Koramansah Park' : 'Away Park'}
                                </div>
                            </div>

                            <div className="matches__played__body__team">
                                <img
                                    src={!nextData.type ? props.data.teams[getTeamIndex('nsoatreman', props.data.teams)].logo : props.data.teams[getTeamIndex(nextData.team.split(' ')[0], props.data.teams)].logo}
                                    alt="other Team Logo"
                                    className="matches__played__body__team__logo"
                                />
                                <p>{!nextData.type ? props.data.teams[getTeamIndex('nsoatreman', props.data.teams)].name : props.data.teams[getTeamIndex(nextData.team.split(' ')[0], props.data.teams)].name}</p>
                            </div>

                        </div>

                        <div className="matches__played__btnbox">
                            <button className="matches__played__btn btn">Buy Tickets</button>
                            <button className="matches__played__btn btn">Head to Head</button>
                            <button
                                className="matches__played__btn btn"
                                onClick={() => window.open('https://www.mybet.africa/sport')}
                            >
                                Mybet Africa
                            </button>
                        </div>
                    </div>

                    <div className="matches__table">
                        <div className="matches__table__head">
                            <div className="matches__table__head__trophy">
                                <GiTrophy className='matches__table__head__trophy__icon' />
                                <span>Premier League</span>
                            </div>
                            <div className="matches__table__head__title">Full Table</div>
                        </div>
                        <div className="matches__table__labels">
                            <div className='matches__table__labels__left' />
                            <div className='matches__table__labels__right'>
                                <span>P</span>
                                <span>W</span>
                                <span>D</span>
                                <span>L</span>
                                <span>Pts</span>
                            </div>
                        </div>

                        <div className="matches__table__container">

                            {
                                (tableData.slice(0, 6)).map((item, index) => (
                                    <div className="matches__table__row">
                                        <div className='matches__table__labels__left'>
                                            <span>{++index}</span>
                                            <span><img src={item.logo} alt="Team Badge" className='matches__table__row__img' /></span>
                                            <span>{item.name}</span>
                                        </div>
                                        <div className='matches__table__labels__right'>
                                            <span>{item.matchesplayed}</span>
                                            <span>{item.matcheswon}</span>
                                            <span>{item.matchesdrew}</span>
                                            <span>{item.matcheslost}</span>
                                            <span>{item.points}</span>
                                        </div>
                                    </div>
                                ))
                            }


                        </div>

                    </div>
                </div>
            </div>
    )
}



export default Matches

const getTeamIndex = (name, teamData) => {
    // console.log(name)

    // console.log(name.split(' ')[0].toLowerCase())
    const itemIndex = teamData.findIndex(x => x.name.split(' ')[0].toLowerCase() === name.split(' ')[0].toLowerCase())
    // console.log(itemIndex)
    // console.log(teamData[itemArray[0].index])
    return itemIndex;

}

/*
function groupMatchesBySeason(matches) {
    const groupedMatches = {};

    matches.forEach(match => {
        const season = match.season;
        if (!groupedMatches[season]) {
            groupedMatches[season] = [];
        }
        groupedMatches[season].push(match);
    });

    return groupedMatches;
}
*/


function sortTeams(teams) {
    return teams.sort((a, b) => {
        // Compare by points in descending order
        if (b.points !== a.points) {
            return b.points - a.points;
        }

        // If points are the same, compare by (goalsscored - goalsconceded) in descending order
        const goalDifferenceA = a.goalsscored - a.goalsconceded;
        const goalDifferenceB = b.goalsscored - b.goalsconceded;

        if (goalDifferenceB !== goalDifferenceA) {
            return goalDifferenceB - goalDifferenceA;
        }

        // If (goalsscored - goalsconceded) is the same, compare by name in ascending order
        return a.name.localeCompare(b.name);
    });
}

