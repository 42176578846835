import React, { useState, useRef, useEffect } from 'react'
import _, { now } from 'lodash'
import ImageRodal from './ImageRodal';
import { ModalAnimation } from '@dorbus/react-animated-modal';
import { Spinner } from '../shared'
import { NoInternet } from '../shared';
// import GalleryData from '../shared/GalleryData.json';
import { loadGalleryData } from '../shared/loadData'
function GalleryMain() {

    const [height, setHeight] = useState(200)
    const [width, setWidth] = useState(200)
    const [showModal, setShowModal] = useState(true)
    const [currentImg, setCurrentImg] = useState()
    const [showSpinner, setShowSpinner] = useState(true)
    const [pageData, setPageData] = useState([]);
    const [noWifi, setNoWifi] = useState(false)

    const ModalRef = useRef(null);

    useEffect(() => {
        loadGalleryData(setPageData, setShowSpinner)
    }, [])


    return (
        noWifi ?
        <NoInternet />
        :
        <div className='gallery'>

            <ImageRodal
                ModalRef={ModalRef}
                close={() => setShowModal(false)}
                imgSource={currentImg}
            />
            <div className="gallery__container">
                {
                    showSpinner ?
                        <Spinner showSpinner={showSpinner} /> :

                        _.isEmpty(pageData) ? <div /> :
                            pageData.map((item, index) => (
                                <ImageItem
                                    item={item}
                                    index={index}
                                    setShowModal={setShowModal}
                                    setHeight={setHeight}
                                    setWidth={setWidth}
                                    setCurrentImg={setCurrentImg}
                                    ModalRef={ModalRef}
                                />
                            ))
                }
            </div>

        </div>
    )
}

export default GalleryMain


const ImageItem = (props) => {
    return (
        <div
            className="gallery__item"
            onClick={() => handleImageClick(props)}
        >
            <img src={props.item.imgurl} alt="Team Gallery" id={`galleryimg${props.index}`} />
            <p>{props.item.title}</p>
        </div>
    )
}

const handleImageClick = (props) => {
    // console.log(props)

    props.setCurrentImg(props.item.imgurl)

    //var img = document.getElementById(`galleryimg${props.index}`);
    //or however you get a handle to the IMG

    // console.log(img)


    // let width = img.naturalWidth;
    // let height = img.naturalHeight;

    /// console.log(width)
    // console.log(height)
    // props.setWidth(width + 20)
    // props.setHeight(height + 20)
    props.ModalRef.current?.OpenModal(ModalAnimation.Reveal)

    window.scrollTo(0, 0)
}