import React, { createContext, useState } from 'react'

const AppDataContext = createContext();

const AppDataProvider = ({children}) => {
    const [pageData, setPageData] = useState({})
    const [showSpinner, setShowSpinner] = useState(true)
    const [noWifi, setNoWifi] = useState(false);

    return (
        <AppDataContext.Provider value={{ pageData, setPageData, showSpinner, setShowSpinner, noWifi, setNoWifi }}>
            {children}
        </AppDataContext.Provider>
    )
}

export {AppDataContext, AppDataProvider};