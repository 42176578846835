import React, {useContext} from 'react'
import { AppDataContext } from '../Contexts/AppDataContext'
import { GiTrophy } from 'react-icons/gi'
import Trophy from '../img/trophy.png'
import PlayerItem from './PlayerItem'

import PlayerData from '../shared/PlayerData.json'


function SinglePlayerProfile(props) {

    const {pageData} = useContext(AppDataContext)

    // console.log(props)
    return (
        <div className='singleplayer__profile'>
            <div className="singleplayer__profile__stats">

                <div className="singleplayer__profile__stats__item">
                    <h2 className="singleplayer__profile__stats__item__head">Personal Details</h2>
                    <div className="singleplayer__profile__stats__item__body">
                        <div className="singleplayer__profile__stats__item__body__personal__record">
                            <div className="singleplayer__profile__stats__item__body__personal__record__title">Place Of Birth</div>
                            <div className="singleplayer__profile__stats__item__body__personal__record__value">{props.data.placeofbirth}</div>
                        </div>

                        <div className="singleplayer__profile__stats__item__body__personal__record">
                            <div className="singleplayer__profile__stats__item__body__personal__record__title">Date Of Birth</div>
                            <div className="singleplayer__profile__stats__item__body__personal__record__value">{props.data.dateofbirth}</div>
                        </div>

                        <div className="singleplayer__profile__stats__item__body__personal__record">
                            <div className="singleplayer__profile__stats__item__body__personal__record__title">Weight</div>
                            <div className="singleplayer__profile__stats__item__body__personal__record__value">{`${props.data.weight}kg`}</div>
                        </div>

                        <div className="singleplayer__profile__stats__item__body__personal__record">
                            <div className="singleplayer__profile__stats__item__body__personal__record__title">Height</div>
                            <div className="singleplayer__profile__stats__item__body__personal__record__value">{`${props.data.height}cm`}</div>
                        </div>
                    </div>
                </div>


                <div className="singleplayer__profile__stats__item">
                    <h2 className="singleplayer__profile__stats__item__head">Statistics</h2>
                    <div className="singleplayer__profile__stats__item__body">
                        <div className="singleplayer__profile__stats__item__body__statistics__record">
                            <div className="singleplayer__profile__stats__item__body__statistics__record__title">Fixtures Played</div>
                            <div className="singleplayer__profile__stats__item__body__statistics__record__value">{props.data.fixturesplayed}</div>
                        </div>

                        <div className="singleplayer__profile__stats__item__body__statistics__record">
                            <div className="singleplayer__profile__stats__item__body__statistics__record__title">Assists w/goal</div>
                            <div className="singleplayer__profile__stats__item__body__statistics__record__value">{props.data.assists}</div>
                        </div>

                        <div className="singleplayer__profile__stats__item__body__statistics__record">
                            <div className="singleplayer__profile__stats__item__body__statistics__record__title">Minutes Played</div>
                            <div className="singleplayer__profile__stats__item__body__statistics__record__value">{props.data.minutesplayed}</div>
                        </div>

                        <div className="singleplayer__profile__stats__item__body__statistics__record">
                            <div className="singleplayer__profile__stats__item__body__statistics__record__title">Goals Scored</div>
                            <div className="singleplayer__profile__stats__item__body__statistics__record__value">{props.data.goals}</div>
                        </div>
                    </div>
                </div>


                <div className="singleplayer__profile__stats__item">
                    <h2 className="singleplayer__profile__stats__item__head">Honours</h2>

                    <div className="singleplayer__profile__stats__item__body">

                        <div className="singleplayer__profile__stats__item__body__honours__trophy">
                            <img src={Trophy} alt="Trophy" className="singleplayer__profile__stats__item__body__honours__trophy__img" />
                            <div className="singleplayer__profile__stats__item__body__honours__trophy__details">
                                <p className="singleplayer__profile__stats__item__body__honours__trophy__details__cups">0</p>
                                <p className="singleplayer__profile__stats__item__body__honours__trophy__details__title">Premier League</p>
                            </div>
                        </div>

                        {
                            props.data.trophies.map((item, index) => (
                                <TrophyItem
                                    data={item}
                                    index={index}
                                />
                            ))
                        }



                    </div>
                </div>

            </div>

            <div className="singleplayer__profile__bio">
                <div className="singleplayer__profile__bio__title">Biography</div>

                <div className="singleplayer__profile__bio__text">
                Mathis, a seasoned footballer, dedicated an impressive eight years of his career to the vibrant and competitive football scene in Ghana.
                His journey in Ghanaian football was marked by unwavering dedication and a profound passion for the sport. 
                Just two months before the League Cup, Mathis could be found at home, eagerly hoping for a recall from his 
                country's national team coach and a chance to secure a coveted starting position, reflecting his deep commitment
                to both his club and his homeland.

                </div>

                <div className="singleplayer__profile__bio__text">
                    In addition to his football endeavors, Mathis also dedicated time to his education, spending a year studying in
                    France, demonstrating his commitment to personal growth both on and off the field.
                    Notably, he shares a cultural connection with many of his teammates,
                    which adds a unique dimension of camaraderie to his football journey. Mathis's story is a testament to his dedication to Ghanaian football,
                    where he made a significant impact during his eight seasons, and his unwavering commitment to representing his homeland on the pitch.
                </div>
            </div>

            <div className="singleplayer__profile__otherplayers">

                {
                    props.randoms.map((item, index) => (
                        <PlayerItem
                        data={pageData.playerData[item]}
                        index={item}
                />
                    ))
                }
                
            </div>

        </div>
    )
}



export default SinglePlayerProfile

const TrophyItem = (props) => {

    return (
        <div className="singleplayer__profile__stats__item__body__honours__record">
            <div className="singleplayer__profile__stats__item__body__honours__record__value">{props.data.number}</div>
            <div className="singleplayer__profile__stats__item__body__honours__record__title">{props.data.name}</div>
        </div>
    )
}
