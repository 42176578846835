import React, { useEffect, useContext } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


import { AppLanding } from './Landing';
import { Fixtures, LeagueTable, MatchDetails } from './Fixtures';
import { AllPlayersLanding, PlayerSingle } from './AllPlayers';
import { HistoryPage } from './History';
import { FullShop } from './Shop'
import { GalleryPage } from './Gallery'
import { Blog, ReadBlog } from './Blog'

import { AppDataContext } from "./Contexts/AppDataContext";
import { loadLandingData } from './shared/loadData'

function Navigator() {


  const { setPageData, setShowSpinner, setNoWifi } = useContext(AppDataContext)

  useEffect(() => {
    loadLandingData(setPageData, setShowSpinner, setNoWifi)
  }, [])


  return (

    <Router>


      {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}


      <Routes>
        <Route path="/blog" element={<Blog />} />
        <Route path="/readblog/:id" element={<ReadBlog />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/shop" element={<FullShop />} />
        <Route path="/singleplayer/:id" element={<PlayerSingle />} />
        <Route path="/history" element={<HistoryPage />} />

        <Route path="/players" element={<AllPlayersLanding />} />
        <Route path="/matchdetails/:id" element={<MatchDetails />} />
        <Route path="/standings" element={<LeagueTable />} />
        <Route path="/fixtures" element={<Fixtures />} />
        <Route path="/" element={<AppLanding />} />

      </Routes>

    </Router>

  )
}

export default Navigator