import React, { useContext, useEffect, useState } from 'react'
import { AppDataContext } from '../Contexts/AppDataContext'
import { loadLandingData } from '../shared/loadData';
import { useNavigate } from 'react-router-dom';
import { NoInternet, Spinner } from '../shared';
import MyTeam from '../img/logo-nsfc-79x100.png';
import _ from 'lodash'
import { SeasonDropDown } from '../shared'
import NoTeam from '../img/derby.png'

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


function Matches() {

    const { pageData, setPageData } = useContext(AppDataContext)
    const [showSpinner, setShowSpinner] = useState(false)
    const [noWifi, setNoWifi] = useState(false)
    const [fixtureData, setFixtureData] = useState([])
    const [selectedSeason, setSelectedSeason] = useState("")
    const [seasonMatches, setSeasonMatches] = useState([])
    const [allAppData, setAllData] = useState({})

    let currentFixtureArray = pageData.matchesData

    useEffect(() => {
        
        async function fetchData() {
            try {
                const data = await loadLandingData(setAllData, setShowSpinner, setNoWifi, true)
                // console.log(data)
                // console.log(pageData)

                setSelectedSeason(data.seasons[data.seasons.length - 1].display)
                const filteredGroupMatches = data.groupedMatches.filter(group => group.season === selectedSeason)
                let resultArray = addStartEndToGroups(filteredGroupMatches)

                setFixtureData(resultArray)

                // Filter matches to remain only current season matches
                const filteredSeasonMatches = data.matchesData.filter(match => match.season === selectedSeason)
                setSeasonMatches(filteredSeasonMatches)
                setPageData(data);
            } catch (e) {
                console.log(e)
            }

        }

        if (_.isEmpty(pageData.groupedMatches)) {
            setShowSpinner(true)
            fetchData();
            // setShowSpinner(false)

        } else {
            // Filter group matches to get only that of this season before you assign the indexes
            setSelectedSeason(pageData.seasons[pageData.seasons.length - 1].display)
            /* 
                It is -2 becuase i have created season 2023/2024 but has no match
                so it should be changed to -1 to reflect the current season.
                At the end of the test, it should be so..
            */
            const filteredGroupMatches = pageData.groupedMatches.filter(group => group.season === selectedSeason)
            let resultArray = addStartEndToGroups(filteredGroupMatches)

            setFixtureData(resultArray)

            // Filter matches to remain only current season matches
            const filteredSeasonMatches = pageData.matchesData.filter(match => match.season === selectedSeason)
            setSeasonMatches(filteredSeasonMatches)
        }
    }, [])


    useEffect(() => {
        if (_.isEmpty(pageData.groupedMatches)) {
            return
        } else {
            const filteredGroupMatches = pageData.groupedMatches.filter(group => group.season === selectedSeason)
            let resultArray = addStartEndToGroups(filteredGroupMatches)

            setFixtureData(resultArray)

            // Filter matches to remain only current season matches
            const filteredSeasonMatches = pageData.matchesData.filter(match => match.season === selectedSeason)
            setSeasonMatches(filteredSeasonMatches)
        }


    }, [selectedSeason])




    function addStartEndToGroups(groupedDates) {
        let start = 0;
        const result = groupedDates.map(group => {
            const { count } = group;
            const end = start + count;

            const resultGroup = {
                year: group.year,
                month: group.month,
                count,
                start,
                end
            };

            start = end; // Update start for the next group

            return resultGroup;
        });

        return result;
    }


    return (

        showSpinner ?
            <Spinner showSpinner={true} />
            :
            noWifi ?
                <NoInternet />
                :
                <div className='allMatches'>
                    <div className="allMatches__header">
                        <span>Fixtures</span>
                    </div>
                    {
                        _.isEmpty(fixtureData) ?
                            <div />
                            :
                            <SeasonDropDown
                                value={selectedSeason}
                                setInput={setSelectedSeason}
                                id="season-dropdown"
                                list={pageData.seasons.map(season => season.display)}
                            />
                    }


                    <div className="allMatches__body">

                        {
                            _.isEmpty(fixtureData) ? <div /> :
                                fixtureData.map((group, index) => {

                                    return (
                                        <MonthlyMatchList
                                            year={group.year.toString()}
                                            month={MONTHS[parseInt(group.month)]}
                                            data={seasonMatches.slice(group.start, group.end)}

                                        />
                                    )
                                })
                        }

                    </div>
                </div>


    )
}

const MatchItem = (props) => {
    const navigate = useNavigate()
    // console.log(props)
    return (
        props.type ? (
            <div
                className="allMatches__list__fixtures__item"
                onClick={() => handleMatchClick(props.played, props.fsID, navigate)}
            >
                <div className="allMatches__list__fixtures__item__wrap">
                    <div className="allMatches__list__fixtures__home">
                        <p className="allMatches__list__fixtures__home__date">{new Date(props.date).toDateString()}</p>
                        <p className="allMatches__list__fixtures__home__team">{props.home}</p>
                        <img src={MyTeam} alt="Home Team Logo" />
                    </div>
                    <div className="allMatches__list__fixtures__midbox">
                        {
                            props.played ? (

                                <div className="allMatches__list__fixtures__score">
                                    <span className="allMatches__list__fixtures__score__myteam">{props.homescore}</span>
                                    <span className="allMatches__list__fixtures__score__colon">:</span>
                                    <span className="allMatches__list__fixtures__score__awayteam">{props.awayscore}</span>
                                </div>
                            ) : (
                                <div className="allMatches__list__fixtures__time">{props.time}</div>
                            )
                        }
                    </div>


                    <div className="allMatches__list__fixtures__away">
                        <img src={props.otherTeam} alt="Home Team Logo" />
                        <p className="allMatches__list__fixtures__away__team">{props.away}</p>
                        <p className="allMatches__list__fixtures__away__league">{props.league}</p>
                    </div>
                </div>

                <div className="allMatches__list__fixtures__item__phoneview">
                    <p>{new Date(props.date).toDateString()}</p>
                    <p>{props.league}</p>
                </div>
            </div>

        ) : (
            <div
                className="allMatches__list__fixtures__item"
                onClick={() => handleMatchClick(props.played, props.fsID, navigate)}
            >
                <div className="allMatches__list__fixtures__item__wrap">

                    <div className="allMatches__list__fixtures__home">
                        <p className="allMatches__list__fixtures__home__date">{new Date(props.date).toDateString()}</p>
                        <p className="allMatches__list__fixtures__home__team">{props.home}</p>
                        <img src={props.otherTeam} alt="Home Team Logo" />
                    </div>

                    <div className="allMatches__list__fixtures__midbox">
                        {
                            props.played ? (
                                <div className="allMatches__list__fixtures__score">
                                    <span className="allMatches__list__fixtures__score__myteam">{props.homescore}</span>
                                    <span className="allMatches__list__fixtures__score__colon">:</span>
                                    <span className="allMatches__list__fixtures__score__awayteam">{props.awayscore}</span>
                                </div>
                            ) : (
                                <div className="allMatches__list__fixtures__time">{props.time}</div>
                            )
                        }
                    </div>


                    <div className="allMatches__list__fixtures__away">
                        <img src={MyTeam} alt="Home Team Logo" />
                        <p className="allMatches__list__fixtures__away__team">{props.away}</p>
                        <p className="allMatches__list__fixtures__away__league">{props.league}</p>
                    </div>
                </div>

                <div className="allMatches__list__fixtures__item__phoneview">
                    <p>{new Date(props.date).toDateString()}</p>
                    <p>{props.league}</p>
                </div>
            </div>
        )

    )

}

const MonthlyMatchList = (props) => {

    const { pageData } = useContext(AppDataContext)
    // console.log(props.data)

    return (
        <div className="allMatches__list__box">
            <div className="allMatches__list__head">
                <span className="allMatches__list__head__year">{props.year}</span>
                <span className="allMatches__list__head__seperator">|</span>
                <span className="allMatches__list__head__month">{props.month}</span>
            </div>

            <div className="allMatches__list__fixtures">

                {
                    props.data.map((item, index) => {
                        // console.log(getTeamLogo(item.team))
                        return (

                            <MatchItem
                                fsID={item.fsID}
                                id={item.id}
                                home={item.type ? 'Nsoatreman' : item.team}
                                away={!item.type ? 'Nsoatreman' : item.team}
                                date={item.date}
                                time="3:00PM"
                                type={item.type}
                                played={item.referee === "" ? false : true}
                                otherTeam={getTeamLogo(item.team, pageData.teamData)}
                                homescore={item.home_team.goals.length}
                                awayscore={item.away_team.goals.length}
                                league={item.league}
                            />
                        )
                    })
                }
            </div>
        </div>
    )

}

const handleMatchClick = (played, id, navigate) => {
    if (played) {
        navigate(`/matchdetails/${id}`)
    }
}

const getTeamLogo = (name, allTeamsData) => {
    // console.log(allTeamsData)
    try {
        // console.log(name.split(' ')[0].toLowerCase())
        const itemArray = allTeamsData.filter(x => x.name.split(' ')[0].toLowerCase() === name.split(' ')[0].toLowerCase())
        
        return _.isEmpty(itemArray) ? NoTeam : itemArray[0].logo;
    } catch (error) {
        console.log(error)
    }
}

export default Matches