import React from 'react'
import { Header, Footer } from '../shared'
import AllPlayers from './AllPlayers'

function AllPlayersLanding() {
  return (
    <div>
        <Header />
        <AllPlayers />
        <Footer />
    </div>
  )
}

export default AllPlayersLanding