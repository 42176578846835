import React from 'react'
import { GiTrophy } from 'react-icons/gi'
import Trophy from '../img/trophy.png'

function TeamProfile() {
    return (
        <div className='history__profile'>

            <div className="history__profile__stats">

                <div className="history__profile__stats__item history__profile__stats__item--small">
                    <h2 className="history__profile__stats__item__head">Statistics</h2>
                    <div className="history__profile__stats__item__body">
                        <div className="history__profile__stats__item__body__statistics__record">
                            <div className="history__profile__stats__item__body__statistics__record__title">Active Years</div>
                            <div className="history__profile__stats__item__body__statistics__record__value">2</div>
                        </div>

                        <div className="history__profile__stats__item__body__statistics__record">
                            <div className="history__profile__stats__item__body__statistics__record__title">Fans Honours</div>
                            <div className="history__profile__stats__item__body__statistics__record__value">9</div>
                        </div>

                        <div className="history__profile__stats__item__body__statistics__record">
                            <div className="history__profile__stats__item__body__statistics__record__title">Games Played</div>
                            <div className="history__profile__stats__item__body__statistics__record__value">677</div>
                        </div>

                        <div className="history__profile__stats__item__body__statistics__record">
                            <div className="history__profile__stats__item__body__statistics__record__title">Trophies Won</div>
                            <div className="history__profile__stats__item__body__statistics__record__value">2</div>
                        </div>
                    </div>
                </div>

                <div className="history__profile__stats__item history__profile__stats__item--big">

                    <h2 className="history__profile__stats__item__head">Honours</h2>
                    <div className="history__profile__stats__subitem__container">


                        <div className="history__profile__stats__subitem">


                            <div className="history__profile__stats__item__body">

                                <div className="history__profile__stats__item__body__honours__trophy">
                                    <img src={Trophy} alt="Trophy" className="history__profile__stats__item__body__honours__trophy__img" />
                                    <div className="history__profile__stats__item__body__honours__trophy__details">
                                        <p className="history__profile__stats__item__body__honours__trophy__details__cups">1</p>
                                        <p className="history__profile__stats__item__body__honours__trophy__details__title">Division One Cup</p>
                                    </div>
                                </div>

                                <div className="history__profile__stats__item__body__honours__record">
                                    <div className="history__profile__stats__item__body__honours__record__value">1</div>
                                    <div className="history__profile__stats__item__body__honours__record__title">Best Division Team</div>
                                </div>

                                <div className="history__profile__stats__item__body__honours__record">
                                    <div className="history__profile__stats__item__body__honours__record__value">2</div>
                                    <div className="history__profile__stats__item__body__honours__record__title">Most Disciplined Team</div>
                                </div>

                                <div className="history__profile__stats__item__body__honours__record">
                                    <div className="history__profile__stats__item__body__honours__record__value">1</div>
                                    <div className="history__profile__stats__item__body__honours__record__title">Less Conceded Goals</div>
                                </div>

                            </div>
                        </div>




                        <div className="history__profile__stats__subitem">


                            <div className="history__profile__stats__item__body">

                                <div className="history__profile__stats__item__body__honours__trophy">
                                    <img src={Trophy} alt="Trophy" className="history__profile__stats__item__body__honours__trophy__img" />
                                    <div className="history__profile__stats__item__body__honours__trophy__details">
                                        <p className="history__profile__stats__item__body__honours__trophy__details__cups">0</p>
                                        <p className="history__profile__stats__item__body__honours__trophy__details__title">Ghana Premier League</p>
                                    </div>
                                </div>

                                <div className="history__profile__stats__item__body__honours__record">
                                    <div className="history__profile__stats__item__body__honours__record__value">1</div>
                                    <div className="history__profile__stats__item__body__honours__record__title">Fair Play Award</div>
                                </div>

                                <div className="history__profile__stats__item__body__honours__record">
                                    <div className="history__profile__stats__item__body__honours__record__value">1</div>
                                    <div className="history__profile__stats__item__body__honours__record__title">Fan's Favorite Player</div>
                                </div>

                                <div className="history__profile__stats__item__body__honours__record">
                                    <div className="history__profile__stats__item__body__honours__record__value">2</div>
                                    <div className="history__profile__stats__item__body__honours__record__title">Best Away Stadium</div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div >
    )
}

export default TeamProfile