import React from 'react'
import { Header, Footer } from '../shared'
import HistoryLanding from './HistoryLanding'
import TeamProfile from './TeamProfile'
import TeamHistory from './TeamHistory'
import Staff from './Staff'

function HistoryPage() {
  return (
    <div className='history'>
        <Header />
        <HistoryLanding />
        <TeamProfile />
        <TeamHistory />
        <Staff />
        <Footer />
    </div>
  )
}

export default HistoryPage