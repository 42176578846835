import React from 'react'
import { IoTime } from 'react-icons/io5'

function TeamHistory() {
    return (
        <div className='history__content'>
            <div className="history__content__section">
                <h3 className="history__content__section__head">Timeline</h3>
                <div className="history__content__section__body">
                    <TimelineItem
                        year={"1."}
                        title={"Welcome"}
                        text={"Welcome to Nsoatreman Football Club: Where Premier League Excellence Meets Community Spirit"}
                    />

                    <TimelineItem
                        year={"2."}
                        title={"Ghana Premier League"}
                        text={"Nsoatreman Football Club was founded in the year 2021 with a clear vision - to rise from the first division and claim our rightful place among the elite clubs of Ghana."}
                    />

                    <TimelineItem
                        year={"3."}
                        title={"Club Journey"}
                        text={"Our journey from grassroots to glory is not just a story; it's our identity. It's the tale of the countless hours of practice, the strategic brilliance of our coaches, and the unwavering support of our passionate fans."}
                    />

                    <TimelineItem
                        year={"4."}
                        title={"Our Legacy"}
                        text={"Nsoatreman FC invites you to become a part of our legacy. Whether you're a seasoned football professional, a casual enthusiast, or simply someone who appreciates the beauty of the beautiful game, we offer a welcoming community for all."}
                    />

                    <TimelineItem
                        year={"5."}
                        title={"Baffour Complex"}
                        text={"At the heart of our mission lies the Baffour Complex, a world-class facility that sets the stage for memorable sporting events. Designed to cater not only to professionals but to every member of our community, the Baffour Complex is a symbol of our commitment to making sports accessible and enjoyable for everyone."}
                    />

                    <TimelineItem
                        year={"6."}
                        title={"Stronger Together"}
                        text={"If you've been missing the thrill of sports in your life, Nsoatreman FC is here to help you get back in the game. Whether you aspire to play on the field, support from the stands, or simply be a part of our vibrant sports-loving community, we extend a warm invitation to you."}
                    />
                </div>
            </div>

            <div className="history__content__section">
                <h3 className="history__content__section__head">History</h3>
                <div className="history__content__section__body history__content__section__body--history">
                    <p>
                        Nsoatreman Football Club, nestled in the vibrant community of Nsoatre in the Bono Region of Ghana, proudly represents a rich football legacy.
                        The club achieved a remarkable feat in 2021 when they secured a promotion from the first division to the coveted Premier League.
                        Today, Nsoatreman FC stands as a beacon of football excellence and invites you to be a part of their storied journey.
                    </p>
                    <p>
                        Our story is one of passion and determination. Before Nsoatreman Football Club's emergence, the founders were dedicated athletes who cherished the world of sports.
                        Fueled by the vision of creating a sporting hub that met the highest standards, the Baffour Complex was born.
                        This iconic facility not only bolsters Nsoatre's economy but also opens its doors to sports enthusiasts and athletes from diverse backgrounds.
                    </p>
                    <p>
                        At Nsoatreman FC, inclusivity is at our core. We firmly believe that sports should be a unifying force, accessible to all.
                        Our commitment to making sporting events available to the entire community remains unwavering.
                        We provide a nurturing environment for aspiring athletes, a gathering place for families to share in the excitement of matches,
                        and a platform for the community to rally around their shared love for football.
                    </p>
                    <p>

                    </p>
                    <p>
                        Situated in the heart of Nsoatre, Nsoatreman Football Club signifies more than just a football team;
                        it embodies unity, fervor, and an unyielding pursuit of excellence.
                        Join us on this thrilling journey as we continue to build upon our legacy, which began with the monumental promotion of 2021.
                        Embrace the opportunity to be a part of Nsoatreman FC today and relish the magic of football.
                        Together, we'll craft a lasting heritage that echoes through generations.

                    </p>
                </div>
            </div>
        </div>
    )
}

const TimelineItem = (props) => {
    return (
        <div className="history__content__section__item">
            <h4 className="history__content__section__item__year">{props.year}</h4>

            <div className="history__content__section__item__box">
                <div className="history__content__section__item__iconbox">
                    <IoTime className="history__content__section__item__icon" />
                    <div className="history__content__section__item__border" />
                </div>
                <div className="history__content__section__item__body">
                    <h2>{props.title}</h2>
                    <p>{props.text}</p>
                </div>
            </div>

        </div>
    )
}

export default TeamHistory