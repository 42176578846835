import React from 'react'
import PlayersData from '../shared/PlayerData.json';
import { useNavigate } from 'react-router-dom';
import NoPlayer from '../img/NoPlayer.png';


function Players(props) {

    const navigate = useNavigate()

    return (
        <div className='players'>
            <div className="players__header">
                <div className="players__header__main">
                    <h4>Players</h4>
                    <div className="players__header__box">
                        <p>GoalKeepers</p>
                        <p>Defenders</p>
                        <p>Midfielders</p>
                        <p>Striker</p>
                    </div>
                </div>

                <div 
                    className="players__header__btnsection"
                    onClick={() => navigate('/players')}
                >
                    <button className='players__header__btn btn'>All Players</button>
                </div>
            </div>

            <div className="players__body">


                <PlayerItem
                    data={props.data[6]}
                    index={6}
                    allPlayers={props.data}
                />

                <PlayerItem
                    data={props.data[14]}
                    index={14}
                    allPlayers={props.data}
                />

                <PlayerItem
                    data={props.data[21]}
                    index={21}
                    allPlayers={props.data}
                />

            </div>


        </div>
    )
}


const PlayerItem = (props) => {
    
    const navigate = useNavigate()

    return (
        <div 
            className="players__item"
            onClick={() => navigateToPlayer(navigate, props.index, props.allPlayers)}
        >
            <div className="players__item__details">
                <div className="players__item__details__name">
                    <span>{(props.data.fullname).split(' ')[0]}</span>
                    <span>{(props.data.fullname).split(' ')[1]}</span>
                </div>
                <p className="players__item__details__position">{props.data.role}</p>
                <p className="players__item__details__number">{props.data.jerseynumber}</p>
            </div>
            <img src={props.data.imgurl === '' ? NoPlayer : props.data.imgurl} alt="Player Representation" className="players__item__img" />
        </div>
    )

}

export default Players

const navigateToPlayer = (navigate, index, allData) => {

    navigate(`/singleplayer/${allData[index].id}`)

}
