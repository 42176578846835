import React, {useContext} from 'react'
import { AppDataContext } from '../Contexts/AppDataContext'
import NoPlayer from '../img/NoPlayer.png';
import { useNavigate } from 'react-router-dom';
// import PlayerData from '../shared/PlayerData.json'

const PlayerItem = (props) => {

    const navigate = useNavigate()
    const {pageData} = useContext(AppDataContext)
    // console.log(props.data)
    // console.log(props.index)

    return (
        <div 
            className="allplayers__item"
            onClick={() => navigateToPlayer(navigate, props.index, pageData.playerData)}
        >
            <div className="allplayers__item__details">
                <div className="allplayers__item__details__name">
                    <span>{(props.data.fullname).split(' ')[0]}</span>
                    <span>{(props.data.fullname).split(' ')[1]}</span>
                </div>
                <p className="allplayers__item__details__position">{props.data.role}</p>
                <p className="allplayers__item__details__number">{props.data.jerseynumber}</p>
            </div>
            <img src={props.data.imgurl === '' ? NoPlayer : props.data.imgurl} alt="Player Representation" className="allplayers__item__img" />
        </div>
    )

}

const navigateToPlayer = (navigate, index, allPlayers) => {

    navigate(`/singleplayer/${allPlayers[index].id}`)

}


export default PlayerItem