import React from 'react'
import MyTeam from '../img/historylogo.png'

function HistoryLanding() {
  return (
    <div className='history__landing'>
        <div className="history__landing__team">
            <p className="history__landing__team__year">Efiri Tete</p>
            <p className="history__landing__team__title">Nsoatreman</p>
            <p className="history__landing__team__club">Football Club</p>
        </div>

        <div className="history__landing__logobox">
            <img src={MyTeam} alt="team Icon" className="history__landing__logo" />
        </div>
    </div>
  )
}

export default HistoryLanding