import React, { useContext, useEffect, useState } from 'react'

import { AppDataContext } from '../Contexts/AppDataContext'
import { loadLandingData } from '../shared/loadData';
import PlayerItem from './PlayerItem';
import _ from 'lodash'
import { NoInternet, Spinner } from '../shared';
import TT1 from '../img/Amoako.png'
import TT2 from '../img/Christian Asante.png';
import TT3 from '../img/Emmanuel.png';
import TT4 from '../img/Maxwell Konadu.png';
import TT5 from '../img/Solomon.png';

function AllPlayers() {

  const { pageData, setPageData } = useContext(AppDataContext)
  const [showSpinner, setShowSpinner] = useState(false)
  const [noWifi, setNoWifi] = useState(false)
  // console.log(pageData.playerData)

  useEffect(() => {

    if (_.isEmpty(pageData.playerData)) {
      setShowSpinner(true)
      loadLandingData(setPageData, setShowSpinner, setNoWifi)
    }

  }, [])


  return (
    showSpinner ?
      <Spinner showSpinner={true} />
      :
      noWifi ?
        <NoInternet />
        :
        <div className='allplayers'>

          <div className="allplayers__banner">
            <span>Players</span>
          </div>
          <div className="allplayers__header">
            <div className="allplayers__header__main">
              <h4>All Players</h4>
              <div className="allplayers__header__box">
                <p>GoalKeepers</p>
                <p>Defenders</p>
                <p>Midfielders</p>
                <p>Striker</p>
              </div>
            </div>

            <div
              className="allplayers__header__btnsection"
            >
              <button className='allplayers__header__btn btn'>All players</button>
            </div>
          </div>

          <div className="allplayers__body">

            {
              _.isEmpty(pageData) ?
                <div /> :
                pageData.playerData.map((item, index) => {
                  // console.log(item)
                  return (
                    <PlayerItem
                      data={item}
                      index={index}
                    />
                  )

                })
            }
          </div>

          <div className="allplayers__managers">

            <h4>Technical Team</h4>
            <div className="allplayers__managers__body">

              <ManagerItem
                fname="Maxwell"
                lname="Konadu"
              >
                <img src={TT4} alt="Manager Representation" className="allplayers__managers__item__img" />
              </ManagerItem>


              <ManagerItem
                fname="Isaac"
                lname="Amoako"
              >
                <img src={TT1} alt="Manager Representation" className="allplayers__managers__item__img" />
              </ManagerItem>

              <ManagerItem
                fname="Asante"
                lname="Christian"
              >
                <img src={TT2} alt="Manager Representation" className="allplayers__managers__item__img" />
              </ManagerItem>

              <ManagerItem
                fname="Emmanuel N. A."
                lname="Amartey"
              >
                <img src={TT3} alt="Manager Representation" className="allplayers__managers__item__img" />
              </ManagerItem>

              <ManagerItem
                fname="Solomon A. K."
                lname="Obeng"
              >
                <img src={TT5} alt="Manager Representation" className="allplayers__managers__item__img" />
              </ManagerItem>

            </div>
          </div>

        </div>
  )
}


const ManagerItem = (props) => {
  return (
    <div className="allplayers__managers__item">
      {props.children}


      <div className="allplayers__managers__item__name">
        <span>{props.fname}</span>
        <span>{props.lname}</span>
      </div>
    </div>

  )
}

export default AllPlayers