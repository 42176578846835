import React from 'react'

function SeasonDropDown(props) {

    return (
        <div className='seasondrop'>
            <select
                className="seasondrop__input"
                onChange={(e) => props.setInput(e.target.value)}
                value={props.value}
                id={props.id}
                style={props.value === "" ? { color: 'grey', fontWeight: 'bold' } : {}}
            >
                {
                    props.list.map((item, index) => <option value={item}>{item}</option>)
                }
            </select>
        </div>
    )
}

export default SeasonDropDown