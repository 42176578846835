import React, { useEffect, useState, useContext } from 'react'
import { Header, Footer, SeasonDropDown, Spinner, NoInternet } from '../shared'
import { AppDataContext } from '../Contexts/AppDataContext';
import _ from 'lodash'
import { loadLandingData } from '../shared/loadData';

function LeagueTable() {

    const { pageData, setPageData } = useContext(AppDataContext)

    const [tableData, setTableData] = useState(pageData.teamData)
    const [selectedSeason, setSelectedSeason] = useState("")
    const [showSpinner, setShowSpinner] = useState(false)
    const [noWifi, setNoWifi] = useState(false)
    const [data, setData] = useState([])
    useEffect(() => {

        async function fetchData() {
            const data = await loadLandingData(setData, setShowSpinner, setNoWifi, 1)

            setSelectedSeason(data.seasons[data.seasons.length - 1].display)
            const filteredTeams = data.teamData.filter(team => team.league === "Ghana Premier League" && team.season === selectedSeason)
            const sortedData = sortTeams(filteredTeams)
            setTableData(sortedData)
            setPageData(data)
        }

        if (_.isEmpty(pageData)) {
            setShowSpinner(true)
            fetchData()

        } else {
            setSelectedSeason(pageData.seasons[pageData.seasons.length - 1].display)
            const filteredTeams = pageData.teamData.filter(team => team.league === "Ghana Premier League" && team.season === selectedSeason)
            const sortedData = sortTeams(filteredTeams)
            setTableData(sortedData)
        }

    }, [])

    useEffect(() => {
        if (_.isEmpty(pageData)) {
            return 
        } else {
            const filteredTeams = pageData.teamData.filter(team => team.league === "Ghana Premier League" && team.season === selectedSeason)
            const sortedData = sortTeams(filteredTeams)
            setTableData(sortedData)
        }

    }, [selectedSeason])


    return (
        <div className='leaguetable'>

            <Header />

            <div className="leaguetable__head">

                <div className="allMatches__header">
                    <span>League Table</span>
                </div>

                <div className="matches__header">
                    <div className="matches__header__main">
                        <h4 className="matches__header__title">Matches</h4>
                        <div className="matches__header__box">
                            <p>First Team</p>
                            {
                                /*
                                    <p>Women's Team</p>
                                    <p>Under 21</p>
                                    <p>Under 17</p>
                                */
                            }
                        </div>
                    </div>
                    <div className="matches__header__btnbox">
                        <SeasonDropDown
                            value={selectedSeason}
                            setInput={setSelectedSeason}
                            id="season-dropdown"
                            list={_.isEmpty(pageData) ?  [] : pageData.seasons.map(season => season.display)}
                        />
                    </div>
                </div>
            </div>

            <div className="leaguetable__body">

                {
                    showSpinner ?
                        <Spinner showSpinner={showSpinner} /> :

                        noWifi ? <NoInternet /> :

                            <div className="leaguetable__table">

                                <div className="leaguetable__table__labels">
                                    <div className='leaguetable__table__labels__left'>&nbsp;</div>
                                    <div className='leaguetable__table__labels__right'>
                                        <span>P</span>
                                        <span>W</span>
                                        <span>D</span>
                                        <span>L</span>
                                        <span>Pts</span>
                                    </div>
                                </div>

                                <div className="leaguetable__table__container">

                                    {
                                        _.isEmpty(tableData) ? <div /> :
                                        tableData.map((item, index) => (
                                            <div className="leaguetable__table__row">
                                                <div className='leaguetable__table__labels__left'>
                                                    <span>{++index}</span>
                                                    <span className='leaguetable__table__labels__left__badge'><img src={item.logo} alt="Team Badge" className='leaguetable__table__row__img' /></span>
                                                    <span className='leaguetable__table__labels__left__name'>{item.name}</span>
                                                </div>
                                                <div className='leaguetable__table__labels__right'>
                                                    <span>{item.matchesplayed}</span>
                                                    <span>{item.matcheswon}</span>
                                                    <span>{item.matchesdrew}</span>
                                                    <span>{item.matcheslost}</span>
                                                    <span>{item.points}</span>

                                                </div>
                                            </div>
                                        ))
                                    }



                                </div>


                            </div>

                }



            </div>
            <Footer />
        </div>
    )
}

export default LeagueTable

function sortTeams(teams) {
    return teams.sort((a, b) => {
        // Compare by points in descending order
        if (b.points !== a.points) {
            return b.points - a.points;
        }

        // If points are the same, compare by (goalsscored - goalsconceded) in descending order
        const goalDifferenceA = a.goalsscored - a.goalsconceded;
        const goalDifferenceB = b.goalsscored - b.goalsconceded;

        if (goalDifferenceB !== goalDifferenceA) {
            return goalDifferenceB - goalDifferenceA;
        }

        // If (goalsscored - goalsconceded) is the same, compare by name in ascending order
        return a.name.localeCompare(b.name);
    });
}