import { collection, getDocs, getFirestore } from "firebase/firestore";
import { DB, Storage } from "../firebase";
import _ from "lodash";

const loadGalleryData = async (setData, setSpinner, setNoWifi) => {
  let results = [];
  const querySnapshot = await getDocs(collection(getFirestore(), "gallery"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    results.push(doc.data());
  });

  if (_.isEmpty(results)) {
    setNoWifi(true);
  } else {
    setData(results);
  }

  setSpinner(false);
};

const loadLandingData = async (setData, setSpinner, setNoWifi, isReturn) => {
  let matchesData = [];
  let teamData = [];
  let newsData = [];
  let playerData = [];
  let seasons = [];
  let settings = [];

  const querySnapshot = await getDocs(collection(getFirestore(), "matches"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    matchesData.push({ fsID: doc.id, ...doc.data() });
  });

  const querySnapshot2 = await getDocs(collection(getFirestore(), "teams"));
  querySnapshot2.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    teamData.push(doc.data());
  });

  const querySnapshot3 = await getDocs(collection(getFirestore(), "news"));
  querySnapshot3.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    newsData.push(doc.data());
  });

  const querySnapshot4 = await getDocs(collection(getFirestore(), "players"));
  querySnapshot4.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    playerData.push(doc.data());
  });

  const querySnapshot5 = await getDocs(collection(getFirestore(), "seasons"));
  querySnapshot5.forEach((doc) => {
    seasons.push(doc.data());
  });

  const querySnapshot6 = await getDocs(collection(getFirestore(), "settings"));
  querySnapshot6.forEach((doc) => {
    settings.push(doc.data());
  });
  // console.log(settings);

  // First reorder match data in ascending order of dates before setting it here..
  matchesData.sort((a, b) => a.time_stamp.toMillis() - b.time_stamp.toMillis());
  newsData.sort((a, b) => b.time_stamp.toMillis() - a.time_stamp.toMillis());
  //Order seasons to get the current season by the end..
  seasons.sort((a, b) => a.endYear - b.endYear);
  const groupedMatches = _groupFixtures(matchesData);
  // console.log(seasons);
  // console.log(groupedMatches);

  if (_.isEmpty(matchesData)) {
    setNoWifi(true);
  } else {
    setData({
      matchesData,
      teamData,
      newsData,
      playerData,
      groupedMatches,
      seasons,
      settings,
    });
  }

  setSpinner(false);
  return isReturn ? {
    matchesData,
    teamData,
    newsData,
    playerData,
    groupedMatches,
    seasons,
    settings,
  } : {}

};

const _groupFixtures = (matchesData) => {
  const groupedDates = {};

  matchesData.forEach((fixture, index) => {
    // console.log(fixture.time_stamp.toDate())

    const dateObj = fixture.time_stamp.toDate();

    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth()).padStart(2, "0");

    const key = `${year}-${month}`;

    if (groupedDates[key]) {
      groupedDates[key].count++;
    } else {
      /* This operates on the basis that, the season is added for the first count of the year-month pair and
      all other matches in that year month pair must have the same season. 
      Hence there should be no way a month in a year contains matches from two different seasons.
      Since after every season there is a break before the next season starts
      */
      groupedDates[key] = { year, month, count: 1, season: fixture.season };
    }
  });
  // console.log(groupedDates)
  return Object.values(groupedDates);
};

export { loadGalleryData, loadLandingData };
