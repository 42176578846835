import React, { useEffect, useState } from "react";

function CountdownTimer(props) {

   
    const [remainingTime, setRemainingTime] = useState(props.date);
    const [countdownTime, setCountdownTime] = useState(
        {
            countdownDays: '',
            countdownHours: '',
            countdownlMinutes: '',
            countdownSeconds: ''
        }
    );

    const countdownTimer = () => {

        
            const timeInterval = setInterval(() => {

                const countdownDateTime = new Date(props.date).getTime();
                const currentTime = new Date().getTime();
                const remainingDayTime = countdownDateTime - currentTime;
                
                setRemainingTime(remainingDayTime)

                const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
                const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
                const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);
    
                let runningCountdownTime = {
                    countdownDays: totalDays,
                    countdownHours: totalHours,
                    countdownMinutes: totalMinutes,
                    countdownSeconds: totalSeconds
                }
    
                setCountdownTime(runningCountdownTime);
    
                if (remainingDayTime < 0) {
                    clearInterval(timeInterval);
                    
                }
    
            }, 1000);

    }

    useEffect(() => {
        countdownTimer();
    });

    return (
        <>
            <div className="matches__upcoming__countdown">
                <div className="matches__upcoming__countdown__item">
                    <p className="matches__upcoming__countdown__item__value">{remainingTime > 0 ? countdownTime.countdownDays: '00'}</p>
                    <p className="matches__upcoming__countdown__item__label">Days</p>
                </div>

                <div className="matches__upcoming__countdown__item">
                    <p className="matches__upcoming__countdown__item__value">{remainingTime > 0 ? countdownTime.countdownHours : '00'}</p>
                    <p className="matches__upcoming__countdown__item__label">Hours</p>
                </div>

                <div className="matches__upcoming__countdown__item">
                    <p className="matches__upcoming__countdown__item__value">{remainingTime > 0 ? countdownTime.countdownMinutes : '00'}</p>
                    <p className="matches__upcoming__countdown__item__label">Mins</p>
                </div>

                <div className="matches__upcoming__countdown__item">
                    <p className="matches__upcoming__countdown__item__value">{remainingTime > 0 ? countdownTime.countdownSeconds : '00'}</p>
                    <p className="matches__upcoming__countdown__item__label">Secs</p>
                </div>
            </div>
        </>
    )

}
export default CountdownTimer;