import React from "react";
import { AppLanding } from "./Landing";
import { isMobile } from "react-device-detect";
import Navigator from "./Navigator";
import PhoneView from "./PhoneView";
import { AppDataProvider } from "./Contexts/AppDataContext";

function App() {
  

  return (
    <AppDataProvider>
      <div className="App">
        <Navigator />
      </div>
    </AppDataProvider>
  );
}
export default App;
