import React from 'react'
import { isMobile } from 'react-device-detect';

function Cards() {
    return (
        <div className='cards'>
            <div class="row">
                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--1">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--1">Supporters Fan Club</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>One time Season ticket</li>
                                    <li>Visit all away matches</li>
                                    <li>Free Merch</li>
                                    <li>Meet & greet with the stars</li>
                                    <li>Dinner after every win</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card__side card__side--back card__side--back-1">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Only</p>
                                    <p class="card__price-value">$9</p>
                                </div>
                                <a href="#popup" class="btn btn--white">Book now!</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--2">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--2">Youth & Women Team</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>Train your ward</li>
                                    <li>Bus pickup from home</li>
                                    <li>Free Boots</li>
                                    <li>Free energy drinks</li>
                                    <li>Full Injury Treatment</li>
                                </ul>
                            </div>

                        </div>
                        <div class="card__side card__side--back card__side--back-2">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Only</p>
                                    <p class="card__price-value">$49</p>
                                </div>
                                <a href="#popup" class="btn btn--white">Book now!</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-1-of-3">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--3">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--3">Language Skills</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>10 different languages</li>
                                    <li>Experienced tutors</li>
                                    <li>One Job application abroad</li>
                                    <li>Certificate of completion</li>
                                    <li>Award for   best 3 students</li>
                                </ul>
                            </div>

                        </div>
                        <div class="card__side card__side--back card__side--back-3">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Only</p>
                                    <p class="card__price-value">$89</p>
                                </div>
                                <a href="#popup" class="btn btn--white">Book now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SocialCard() {

    let fullWidth = window.innerWidth;
    let width = isMobile ? fullWidth * 0.8: Math.round(fullWidth - (2 * 10.7))/3
    let height = 624;
    

    return (
        <div className="cards__container">

            <div className="players__header">
                <div className="players__header__main">
                    <h4>Social Media</h4>
                    <div className="players__header__box">
                        <p>Youtube</p>
                        <p>Facebook</p>
                        <p>Twitter</p>

                    </div>
                </div>

                <div
                    className="players__header__btnsection"
                    onClick={() => { }}
                >
                    <button className='players__header__btn btn'>Social Media</button>
                </div>
            </div>

            <div className='cards'>
                <div class="row">
                    <div class="col-1-of-3">
                        <div class="card">
                            <iframe
                                src="https://www.youtube.com/embed/TEq0kZ3neGs"
                                title='Our youtube Channel'
                                width={'100%'}
                                height={'100%'}
                            />
                        </div>
                    </div>


                    <div class="col-1-of-3">
                        <div
                            class="card"
                            style={{}}
                        >
                            <iframe
                                title="Facebook Feed"
                                src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100070519853925%26mibextid%3DLQQJ4d&tabs=timeline&width=${width}&height=${height}&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                                width="100%"
                                height="100%"
                                style={{ border: 'none', overflow: 'hidden' }}
                                s// crolling="no"
                                frameborder="0"
                                allowfullscreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            />


                        </div>
                    </div>


                    <div class="col-1-of-3">
                        <div class="card">
                            <iframe
                                title="Twitter Page"
                                src="https://twitframe.com/show?url=https%3A%2F%2Ftwitter.com%2FNsoatreman_FC%2Fstatus%2F1820205697755161060"
                                width="100%"
                                height="100%"
                                style={{ border: 'none', overflow: 'hidden' }}
                                // scrolling="no"
                                frameborder="0"
                                allowfullscreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            />



                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SocialCard