import React, { useEffect, useState, useContext } from 'react'
import { AppDataContext } from '../Contexts/AppDataContext';
import { useLocation, useParams } from 'react-router-dom';
import MatchReport from './MatchReport';
import MatchStats from './MatchStats';
import { Header, Footer, Spinner, NoInternet } from '../shared';
import _ from 'lodash'
import { loadLandingData } from '../shared/loadData';

const item = {
  "id": "",
  "type": 0,
  "date": "",
  "team": "",
  "home_team": {
    "type": 1,
    "goals": [],
    "shots": {
      "total": 0,
      "on_target": 0,
      "off_target": 0,
      "corners": 0
    },
    "passes": {
      "total": 0,
      "accuracy": 0
    },
    "possession": 0,
    "fouls": 0,
    "bookings": [],
    "offsides": 0,
    "lineup": [],
    "subs": []
  },
  "away_team": {
    "type": 0,
    "goals": [],
    "shots": {
      "total": 0,
      "on_target": 0,
      "off_target": 0,
      "corners": 0
    },
    "passes": {
      "total": 0,
      "accuracy": 0
    },
    "possession": 0,
    "fouls": 0,
    "bookings": [],
    "offsides": 0,
    "lineup": [],
    "subs": []
  },
  "league": "Ghana Premier League"
}

function MatchDetails() {

  let { id } = useParams();
  const { pageData, setPageData } = useContext(AppDataContext)

  const [data, setData] = useState(item)
  const [teamIndex, setTeamIndex] = useState(0)

  const [appData, setAppData] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const [noWifi, setNoWifi] = useState(false)

 

  useEffect(() => {
    // console.log('Running')
    async function fetchData() {
      const result = await loadLandingData(setAppData, setShowSpinner, setNoWifi, 1)


      const data = result.matchesData.find(x => x.fsID === id)
      const teamIndex = result.teamData.findIndex(x => x.name.split(' ')[0].toLowerCase() === data.team.split(' ')[0].toLowerCase())
      // console.log(teamIndex)
      // console.log(result.teamData[teamIndex])
      setAppData(result)
      setPageData(result)
      setTeamIndex(teamIndex)
      setData(data)

    }

    // console.log(id)

    if (_.isEmpty(pageData)){
      setShowSpinner(true)
      fetchData()
      
    } else {
      const data = pageData.matchesData.find(x => x.fsID === id)
      const teamIndex = pageData.teamData.findIndex(x => x.name.split(' ')[0].toLowerCase() === data.team.split(' ')[0].toLowerCase())
      // console.log(teamIndex)
      setTeamIndex(teamIndex)
      setData(data)
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });

  }, [id])

  


  return (
    <div>
      <Header />

      {
        showSpinner ? <Spinner showSpinner={showSpinner} /> :

          noWifi ? <NoInternet /> :
            <>
              <MatchReport 
                data={data} 
                index={teamIndex}
                pageData={_.isEmpty(pageData) ? appData : pageData}
              />
              <MatchStats 
                data={data} 
                index={teamIndex}
                pageData={_.isEmpty(pageData) ? appData : pageData}
              />
            </>
      }

      <Footer />
    </div>
  )
}

export default MatchDetails