import React from 'react';
import { AnimatedModal, ModalAnimation } from '@dorbus/react-animated-modal';
import { isMobile } from 'react-device-detect';

const deviceWidth = window.innerWidth;
const deviceHeight = window.innerHeight;

function ImageRodal(props) {
    return (


        <div>
            <AnimatedModal
                ref={props.ModalRef}
                animation={ModalAnimation.Unfold}
            >
                <div>
                    <img
                        src={props.imgSource}
                        alt="Gallery Item"
                        style={isMobile ? { width: '90vw', height: 'auto', objectFit: 'contain' } : { width: '60vw', height: 'auto', objectFit: 'contain' }}
                        className='imgrodal__img'
                    />
                </div>

            </AnimatedModal>
        </div>


    )
}

export default ImageRodal