import React from 'react'
import {
    SiPuma, SiAmazonaws, SiAudiomack, SiBrave, SiHp, SiIntel, SiNotion,
    SiBeatsbydre, SiBurgerking, SiCloud66, SiEpicgames, SiHarmonyos,
    SiApple, SiTwitter, SiPinterest, SiYoutube,
} from 'react-icons/si';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import { GrFacebookOption } from 'react-icons/gr';
import { AiFillInstagram } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';
import Partner1 from '../img/partners/B.A EVENTS.png';
import Partner2 from '../img/partners/attachment_114830262.png';
import Partner3 from '../img/partners/nhis.jpeg';
import Partner4 from '../img/partners/service radio.jpeg';
import MyTeam from '../img/logo-nsfc-79x100.png';


function Footer() {

    const navigate = useNavigate()

    return (
        <div className='footer'>
            <div className="footer__partners">
                <img className='footer__partners__item' src={Partner1} alt="Partners Logo" />
                <img className='footer__partners__item' src={Partner2} alt="Partners Logo" />
                <img className='footer__partners__item' src={Partner3} alt="Partners Logo" />
                <img className='footer__partners__item' src={Partner4} alt="Partners Logo" />

            </div>

            <div className="footer__main">
                <div className="footer__main__left">
                    <div className="footer__main__left__imgbox">
                        <img src={MyTeam} alt="team Logo" className="footer__main__left__img" />
                    </div>
                    <ul className="footer__main__left__list">
                        <li
                            onClick={() => navigate('/fixtures')}
                        >
                            Fixtures
                        </li>
                        <li
                            onClick={() => navigate('/players')}
                        >
                            Players
                        </li>
                        <li
                            onClick={() => navigate('/gallery')}
                        >
                            Gallery
                        </li>
                        <li
                            onClick={() => navigate('/history')}
                        >
                            About Us
                        </li>
                        <li
                            onClick={() => navigate('/blog')}
                        >
                            Blog
                        </li>
                        {
                            /*
                                <li>Shop</li>
                            */
                        }

                    </ul>
                </div>

                <div className="footer__main__right">

                    <div className="footer__main__right__contactus">

                        <ul className="footer__main__right__contactus__ourapps">
                            <span>Download App</span>
                            <li> <SiApple className='footer__main__right__icon' /> </li>
                            <li> <IoLogoGooglePlaystore className='footer__main__right__icon' /> </li>
                        </ul>

                        <ul className="footer__main__right__contactus__socials">
                            <li
                                onClick={() => window.open('https://www.facebook.com/profile.php?id=100070519853925&mibextid=LQQJ4d')}
                            >
                                <GrFacebookOption className='footer__main__right__icon' />
                            </li>
                            <li
                                onClick={() => window.open('https://twitter.com/Nsoatreman_FC')}
                            >
                                <SiTwitter className='footer__main__right__icon' />
                            </li>
                            {
                                /*
                                    <li><AiFillInstagram className='footer__main__right__icon' /></li>
                                    <li><SiPinterest className='footer__main__right__icon' /></li>
                                */
                            }

                            <li
                                onClick={() => window.open('https://youtube.com/@Nsoatreman_fc')}
                            >
                                <SiYoutube className='footer__main__right__icon' />
                            </li>
                        </ul>

                    </div>

                    <div className="footer__main__right__copyright">
                        <p>
                            © Copyright Nsoatreman Official website of  NsoatremanFC.
                        </p>

                        <ul className="footer__main__right__copyright__list">
                            <li>Legal Terms</li>
                            <li>Privacy Policy</li>

                            <li>Contact Us</li>
                            <li>Support/FAQs</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer