import React from 'react'
/*
import Board1 from '../img/board/Baffuor Awuah.png';
import Board2 from '../img/board/Agnes Oforiwaa.png'
import Board3 from '../img/board/Aziz Abubakar.png';
import Board4 from '../img/board/Edwin Nii.png';
import Board5 from '../img/board/George Ampaabeng.png';
import Board6 from '../img/board/Joseph Cudjoe.png';
import Board7 from '../img/board/Kwaku Bosompem.png';
import Board8 from '../img/board/Mustapha Abdul-hamid.png';
import Board9 from '../img/board/Antwi.png';
import Board10 from '../img/board/Osei Kyei Mensah.png';
import Board11 from '../img/board/Thomas Kyeremeh.png';
import Board12 from '../img/board/Yaw Owusu Brempong.png';

const images = [Board1, Board2, Board3, Board4, Board5, Board6, Board7, Board8, Board9, Board10, Board11, Board12]

*/

const data = [
    { name: 'Hon. Ignatius Baffuor Awuah', position: 'Executive Board Chair', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FBaffuor%20Awuah.png?alt=media&token=5e4754e1-aa34-43ca-9712-f88692cdb74f'},
    { name: 'Mrs Agnes oforiwaa', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FAgnes%20Oforiwaa.png?alt=media&token=921b063c-86ab-4e1e-a05b-973f3e450772' },
    { name: 'Mr Aziz Abubakar', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FAziz%20Abubakar.png?alt=media&token=2d65fead-ff58-4b16-b904-066797e36b85' },
    { name: 'Hon. Edwin Nii Lamptey', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FEdwin%20Nii.png?alt=media&token=32111577-4929-4394-b3f3-50d515962c12' },
    { name: 'Mr George Ampaabeng', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FGeorge%20Ampaabeng.png?alt=media&token=ce2c9b3b-6391-4234-b6c5-8d112a7e2afe' },
    { name: 'Hon. Joseph Kudjoe', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FJoseph%20Cudjoe.png?alt=media&token=e93b2315-daa3-4b29-99e9-e812b8dae224' },
    { name: 'Nana Kwaku Bosompim II', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FKwaku%20Bosompem.png?alt=media&token=bf63a9d9-7f59-4c28-8864-74238094074c' },
    { name: 'Dr Mustapha Abdul-Hamid', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FMustapha%20Abdul-hamid.png?alt=media&token=75882b9e-24a9-499d-b682-fe1099e55775' },
    { name: 'Mr Kingsley Antwi', position: 'Vice Chairman', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FAntwi.png?alt=media&token=46b37c6d-7613-452e-bee4-d9c083cd0243' },
    { name: 'Hon Osei Kyei Mensah Bonsu', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FOsei%20Kyei%20Mensah.png?alt=media&token=037ad4f8-c649-4827-a3db-bf68cd4699e0' },
    { name: 'Dr Thomas Kyeremeh', position: 'Secretary', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FThomas%20Kyeremeh.png?alt=media&token=c7cd6e05-72ad-4aa0-bb69-81adcd343de6' },
    { name: 'Mr Yaw Owusu Brempong', position: 'Board Member', imgurl: 'https://firebasestorage.googleapis.com/v0/b/elitesfc-71072.appspot.com/o/board%2FYaw%20Owusu%20Brempong.png?alt=media&token=ab4bd275-24b5-4fdc-9387-14947a5afd59' }
  ]
  

function Staff() {
    return (
        <div className='staff'>
            <div className="staff__head">
                <h3>Our Founding and Board Members</h3>
                <p>
                    Welcome to the heart of our football family, where dedication meets excellence
                    and passion fuels victory – our esteemed board members, the guiding stars behind our team's journey to greatness.
                </p>
            </div>

            <div className="staff__container">

                {
                    data.map((item, index) => (
                        <StaffItem name={item.name} index={index} position={item.position} imgurl={item.imgurl}/>

                    ))
                }

            </div>

        </div>
    )
}

export default Staff

const StaffItem = (props) => {
    return (
        <div className="staff__item">

            <div className="staff__item__img">
                <div />
                <img src={props.imgurl} alt="Board Member" />
            </div>

            <div className="staff__item__details">
                <h4>{props.name}</h4>
                <p>{props.position}</p>
            </div>

        </div>
    )
}