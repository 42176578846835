import React, { useContext, useEffect, useState } from 'react'
import { AppDataContext } from '../Contexts/AppDataContext';
import { GiTrophy } from 'react-icons/gi'
import MyTeam from '../img/logo-nsfc.png';
import Hawks from '../img/logo-shields-3.png';
import Football from '../img/football.png';
import _ from 'lodash'

const months= [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"
]

const days = [
    "Sunday", "Monday", "Tuesday", "Wednesday",
    "Thursday", "Friday", "Saturday"
]

function MatchReport(props) {

    // const {pageData} = useContext(AppDataContext)
    
    // const teamIndex = pageData.teamData.findIndex(x => x.name.split(' ')[0].toLowerCase() === props.data.team.split(' ')[0].toLowerCase())
    
    // console.log(props.pageData)
    const [loading, setLoading] = useState(false)
    

    useEffect(() => {
      if (_.isEmpty(props.pageData)) {
        // console.log('Can be handled here')
      } else {

      }
    }, [])
    
    
    return (
        _.isEmpty(props.pageData) ? <div /> :
        <div className='report'>

            <GiTrophy className='report__trophyicon' />

            <div className="report__score">
                <div className="report__score__team__box report__score__team__box__home">
                    <p className="report__score__team__title">{props.data.type ? 'Nsoatreman FC' : props.data.team}</p>
                    <div className="report__score__team__badge__box">
                        <img
                            src={props.data.type ? MyTeam :  props.pageData.teamData[props.index].logo}
                            alt="Home team badge" className="report__score__team__badge"
                        />
                    </div>
                </div>
                <div className="report__score__result">
                    <span className="report__score__result__myteam">{props.data.home_team.goals.length}</span>
                    <span className="report__score__result__colon">:</span>
                    <span className="report__score__result__otherteam">{props.data.away_team.goals.length}</span>
                </div>
                <div className="report__score__team__box">
                    <div className="report__score__team__badge__box">
                        <img src={!props.data.type ? MyTeam :  props.pageData.teamData[props.index].logo} alt="Away team badge" className="report__score__team__badge" />
                    </div>
                    <p className="report__score__team__title">{!props.data.type ? 'Nsoatreman FC' : props.data.team}</p>
                </div>
            </div>

            <div className="report__highlights">

                <div className="report__highlights__home">

                {
                    props.data.home_team.goals.length > 0 ? (
                        props.data.home_team.goals.map((item, value) => (
                            <GoalItem 
                                name={`${item.scorer.split(' ')[0]} ${item.scorer.split(' ')[0][0]}.`} 
                                time={item.minute} 
                                isHome={props.data.type} 
                            />
                        ))
                    ) : (
                        <div>&nbsp;</div>
                    )
                        
                    }

                </div>

                <div className="report__highlights__stadium">
                    {props.data.type ? 'Nana Koramansah' : 'Away Park'}
                </div>

                <div className="report__highlights__home report__highlights__away">
                {
                    props.data.away_team.goals.length > 0 ? (
                        props.data.away_team.goals.map((item, value) => (
                            <GoalItem 
                                name={`${item.scorer.split(' ')[0]} ${item.scorer.split(' ')[0][0]}.`} 
                                time={item.minute} 
                                isHome={props.data.type} 
                            />
                        ))
                    ) : (
                        <div>&nbsp;</div>
                    )
                        
                    }
                </div>

            </div>

            <div className="report__bottom">

                <div className="report__bottom__date">
                    <p className="report__bottom__date__dayofmonth">{new Date(props.data.date).getDate()}</p>
                    <div className="report__bottom__date__sub">
                        <p className="report__bottom__date__sub__day">{days[new Date(props.data.date).getDay()]}</p>
                        <p className="report__bottom__date__sub__month">{months[new Date(props.data.date).getMonth()]}</p>
                    </div>
                </div>

                <div className="report__bottom__text">Final Result</div>

                <div className="report__bottom__match">
                    <p className="report__bottom__match__type">{props.data.league}</p>
                    <p className="report__bottom__match__ref">{`REF: ${props.data.referee}`}</p>
                </div>
            </div>
        </div>
    )
}

const GoalItem = (props) => {
    return (

        props.isHome ? (
            <div className="report__highlights__home__goal">
                <span className='report__highlights__home__goal__name'>{props.name}</span>
                <span className='report__highlights__home__goal__time'>{props.time}</span>
                <span className='report__highlights__home__goal__ball'><img src={Football} alt="Football" /></span>
            </div>
        ) : (
            <div className="report__highlights__home__goal">
                <span className='report__highlights__home__goal__ball'><img src={Football} alt="Football" /></span>
                <span className='report__highlights__home__goal__time'>{props.time}</span>
                <span className='report__highlights__home__goal__name'>{props.name}</span>
            </div>
        )


    )
}

export default MatchReport