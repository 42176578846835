import React, { useState, useContext } from 'react'
import Logo from '../img/logo-nsfc-79x100.png';
import { FaShoppingCart, FaYoutube } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md'
import { AppDataContext } from '../Contexts/AppDataContext';

function Header() {

  const navigate = useNavigate('')
  const {pageData} = useContext(AppDataContext)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showMenu, setShowMenu] = useState(0)
  // console.log(pageData.settings[0].set_last_game)

  return (
    <div className='header'>

      <div
        className="header__logobox"
        onClick={() => navigate('/')}
      >
        <img src={Logo} alt="Club Logo" className="header__logo" />
      </div>

      <ul className="header__list">

        <li>
          <p>
            <span>Fixtures</span>
            <MdKeyboardArrowDown className="header__list__icon" />
          </p>

          <ul
            className='header__list__dropdown'
          >
            <li onClick={() => navigate('/fixtures')}>All Fixtures</li>
            <li onClick={() => navigate('/standings')}>League Table</li>
            <li onClick={() => navigate(`/matchdetails/bAjsbLWLAaBZT1n3BNq8`)}>Match Report</li>
          </ul>
        </li>

        <li
          onClick={() => navigate('/players')}
        >
          <p>
            <span>Players</span>
            {/*<MdKeyboardArrowDown className="header__list__icon" />*/}
          </p>
          {
            /*
            <ul 
                className='header__list__dropdown'
              >
                <li onClick={() => navigate('/players')}>All Players</li>
                <li onClick={() => navigate('/singleplayer')}>Single Player</li>

              </ul>
            
            */
          }

        </li>

        <li onClick={() => navigate('/gallery')}>Gallery</li>

        <li onClick={() => navigate('/history')}>About Us</li>


        {
          /*
            <li onClick={() => navigate('/shop')}>Shop</li>
          */
        }
        <li onClick={() => navigate('/blog')}>Blog</li>

      </ul>


      <div className="header__account">
        {
          /*

          <div className="header__account__cart">
          <span className='header__account__cart__price'>$0.00</span>
          <FaShoppingCart className='header__account__cart__icon' />
          <span className='header__account__cart__value'>0</span>
        </div>

          */
        }

        <FaYoutube
          size={28}
          color='red'
          onClick={() => window.open('https://youtube.com/@Nsoatreman_fc')}
          style={{ cursor: 'pointer' }}
        />

      </div>

      <div
        className="header__menu"
        onClick={() => setShowDropdown(!showDropdown)}
      >

        <div className="header__menu__line">
          <div className="header__menu__line--1" />
          <div className="header__menu__line--2" />
          <div className="header__menu__line--3" />
        </div>

        <div
          className="header__menu__dropdown"
          style={showDropdown || showMenu !== 0 ? { display: 'block', width: '100vw' } : {}}
        >
          <ul
            className='header__menu__dropdown__container'
          >
            <li onClick={(showMenu) => setShowMenu(showMenu !== 1 ? 1 : 0)}>

              <p>
                <span>Fixtures</span>
                <MdKeyboardArrowDown className="header__list__icon" />
              </p>

              <ul
                className='header__menu__dropdown__list'
                style={showMenu === 1 ? { display: 'block', width: 'auto' } : { display: 'none', width: '0' }}
              >
                <li onClick={() => navigate('/fixtures')}>All Fixtures</li>
                <li onClick={() => navigate('/standings')}>League Table</li>
                <li onClick={() => navigate('/matchdetails/a2BcD3n74hIjKlM9plQ86TU')}>Match Report</li>
              </ul>

            </li>

            <li onClick={() => navigate('/players')}>
              <p>
                <span>Players</span>
                {/*<MdKeyboardArrowDown className="header__list__icon" />*/}
              </p>

              {
                  /*
                    <ul
                      className='header__menu__dropdown__list'
                      style={showMenu === 2 ? { display: 'block', width: 'auto' } : { display: 'none', width: '0' }}
                    >
                      

                      <li onClick={() => navigate('/players')}>All Players</li>
                      <li onClick={() => navigate('/singleplayer')}>Single Player</li>

                    </ul>
                  */
              }

            </li>

            <li onClick={() => navigate('/gallery')}
            >
              Gallery
            </li>

            <li onClick={() => navigate('/history')}
            >
              About Us
            </li>

            {
              /*

               <li onClick={() => navigate('/shop')}
            >
              Shop
            </li>

              */
            }


            <li onClick={() => navigate('/blog')}
            >
              Blog
            </li>

          </ul>
        </div>

      </div>
    </div>
  )
}

export default Header