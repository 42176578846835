import React, { useEffect, useContext, useState } from 'react'
// import NewsData from '../shared/NewsData.json'
import { useNavigate, useLocation } from 'react-router-dom';
import { AppDataContext } from '../Contexts/AppDataContext'
import _ from 'lodash';
import { loadLandingData } from '../shared/loadData';
import { NoInternet, Spinner } from '../shared';

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function Landing() {



    const navigate = useNavigate()
    const { pageData, setPageData } = useContext(AppDataContext)
    const [showSpinner, setShowSpinner] = useState(false)
    const [noWifi, setNoWifi] = useState(false)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (_.isEmpty(pageData.newsData)) {
            setShowSpinner(true)
            loadLandingData(setPageData, setShowSpinner, setNoWifi)
        } else {

        }
    }, [])


    return (
        showSpinner ?
            <Spinner showSpinner={true} />
            :
            noWifi ?
                <NoInternet />
                :

                <div className='news news__container'>
                    <div className="news__header">
                        <div className="news__header__main">
                            <h4>News</h4>
                            <div className="news__header__box">
                                <p>Club</p>
                                {
                                    /*
        
                                    <p>Videos</p>
                                    <p>Players</p>
                                    <p>Transfers</p>
        
                                    */
                                }

                            </div>
                        </div>
                        <div className="news__header__btnsection">
                            <button
                                className='news__header__btn btn'
                                onClick={() => navigate('/blog')}
                            >
                                All Blogs
                            </button>
                        </div>
                    </div>



                    <div className="news__body">
                        {
                            _.isEmpty(pageData.newsData) ?
                                <div /> :
                                <MainNewsItem
                                    data={pageData.newsData[0]}
                                />
                        }


                        <div className="news__body__sub news__body__sub__blog">
                            {
                                _.isEmpty(pageData.newsData) ?
                                    <div />
                                    :
                                    (pageData.newsData.filter((item, index) => index !== 0)).map((item, index) => (
                                        <NewsItem
                                            data={item}
                                            index={index + 1}
                                            size={pageData.newsData.length}
                                        />
                                    ))
                            }
                        </div>

                    </div>
                </div>
    )
}

export default Landing

export const MainNewsItem = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { pageData } = useContext(AppDataContext)

    return (

        <div
            className="news__body__main"
            onClick={() => navigateToBlog(navigate, 0, location, pageData.newsData)}
        >
            <div className="news__body__main__imgbox">
                <img src={props.data.imgurl} alt="" className="news__body__main__img" />
            </div>
            <div className="news__body__main__textbox">
                <div className="news__body__main__textbox__head">
                    <h4>News</h4>
                    <span>{`${monthNames[(props.data.time_stamp).toDate().getMonth()]} ${(props.data.time_stamp).toDate().getDate()}, ${props.data.time_stamp.toDate().getFullYear()}`}</span>
                </div>
                <div className="news__body__main__textbox__content">
                    {props.data.header}
                </div>
            </div>
        </div>

    )
}

export const NewsItem = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const { pageData } = useContext(AppDataContext)

    return (
        <div
            className="news__body__sub__item news__body__sub__blog__item"
            onClick={() => navigateToBlog(navigate, props.index, location, pageData.newsData)}
        >
            <div className="news__body__sub__item__imgbox">
                <img src={props.data.imgurl} alt="" className="news__body__sub__item__img" />
            </div>
            <div className="news__body__sub__item__textbox">
                <div className="news__body__sub__item__textbox__head">
                    <h4>News</h4>
                    <span>{`${monthNames[(props.data.time_stamp).toDate().getMonth()]} ${(props.data.time_stamp).toDate().getDate()}, ${props.data.time_stamp.toDate().getFullYear()}`}</span>
                </div>
                <div className="news__body__sub__item__textbox__content">
                    {props.data.header}
                </div>
            </div>
        </div>

    )
}

const navigateToBlog = (navigate, index, location, allNews) => {

    navigate(`/readblog/${allNews[index].id}`)
}

