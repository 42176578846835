import React, { useEffect, useState } from 'react'
import { Header, Footer } from '../shared'
import Matches from './Matches'

function Fixtures() {

  const [showSpinner, setShowSpinner] = useState(false)
  const [noWifi, setNoWifi] = useState(false)

  useEffect(() => {
    
  }, [])
  

  return (
    <div>
        <Header />
        <Matches />
        <Footer />
    </div>
  )
}

export default Fixtures