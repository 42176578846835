import React, { Component } from 'react'
import { SyncLoader } from 'react-spinners';
import {isMobile} from 'react-device-detect'

export class Spinner extends Component {

  render() {
   
    return (
      <div class="spinner" style={this.props.showSpinner ?  {} : {height: '0'}}>
        <SyncLoader color='#333399' loading={this.props.showSpinner} size={isMobile ? 60 : 90} margin={10} />
    </div>
    )
  }
}

export default Spinner