import React, { useEffect } from 'react'
import _ from 'lodash'
import { GiSoccerBall } from 'react-icons/gi'
import { MdStadium } from 'react-icons/md'
import { Storage } from '../firebase'
import { ref, getDownloadURL } from "firebase/storage";
import MatchData from '../shared/MatchData.json';
import AllMatchData from '../shared/AllMatchData.json';

function Landing() {



    useEffect(() => {
        
    }, [])


    return (
        <div className='landing'>
            <div className="landing__textsection">
                <p className="landing__textsection__sub">
                    Nsoatreman Football Club
                </p>
                <div className="landing__textsection__main">
                    <p>Efiri</p>
                    <p>

                        <span>Tete</span>

                    </p>
                    <p>Amanaso</p>
                </div>

                <p className="landing__textsection__footer">
                    Season 23/24
                </p>
            </div>

            <div className="landing__background" />
        </div>
    )
}

export default Landing;