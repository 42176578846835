import React, {useContext} from 'react'
import { AppDataContext } from '../Contexts/AppDataContext';
import { GoPlay } from 'react-icons/go'
import MyTeam from '../img/logo-nsfc.png';
import Hawks from '../img/logo-shields-3.png';
import HomePitch from '../img/pitch-half-top-1-3.png';
import AwayPitch from '../img/pitch-half-bottom-1-3.png';
import { BsFillArrowUpCircleFill, BsFillArrowDownCircleFill } from 'react-icons/bs'
import { GiSoccerBall } from 'react-icons/gi';
import TeamData from '../shared/TeamData.json';
import _ from 'lodash'

function MatchStats(props) {
    // console.log(props)
    // console.log(props.data.home_team.bookings.map(x => !x.yellow_card))

    // const {pageData} = useContext(AppDataContext)
    // console.log(props.pageData)
    return (
        _.isEmpty(props.pageData) ? <div /> :
        <div className='stats'>

            <div className="stats__header">
                <div className="stats__header__main">
                    <h4 className="stats__header__title">Lineups</h4>
                    <div className="stats__header__box">
                        <p>After the Match</p>
                    </div>
                </div>
                <div className="stats__header__btnbox">
                    <button
                        className="stats__header__btn btn"
                        onClick={() => window.open('https://www.youtube.com/@Nsoatreman_fc')}
                    >
                        <span>Watch highlight</span>
                        <GoPlay className='stats__header__btn__icon' />
                    </button>

                </div>
            </div>

            <div className="stats__content">

                {
                    /*
                        This pitch setup would be setup soon.
                    

                        <div className="stats__content__pitch">

                            <div className="stats__content__pitch__home">
                                <div className="stats__content__pitch__home__head">
                                    <div className="stats__content__pitch__home__head__team">
                                        <p className="stats__content__pitch__home__head__team__name">Nsoatreman FC</p>
                                        <img src={MyTeam} alt="Home Team Badge" className="stats__content__pitch__home__head__team__badge" />
                                    </div>

                                    <p className="stats__content__pitch__home__head__formation">4:3:3</p>
                                </div>

                                <div className="stats__content__pitch__home__imgbox">
                                    <img src={HomePitch} alt="Home Pitch layout" className="stats__content__pitch__home__img" />
                                </div>
                            </div>

                            <div className="stats__content__pitch__home">


                                <div className="stats__content__pitch__home__imgbox">
                                    <img src={AwayPitch} alt="Home Pitch layout" className="stats__content__pitch__home__img" />
                                </div>

                                <div className="stats__content__pitch__home__head">
                                    <div className="stats__content__pitch__home__head__team">
                                        <p className="stats__content__pitch__home__head__team__name">Hawks FC</p>
                                        <img src={Hawks} alt="Home Team Badge" className="stats__content__pitch__home__head__team__badge" />
                                    </div>

                                    <p className="stats__content__pitch__home__head__formation">4:4:2</p>
                                </div>
                            </div>

                        </div>

                    */
                }

                <div className="stats__content__pitch">

                    {
                        /*
                        JUST A SIMPLE SETUP TO REPLACE THE PITCH IMAGES FOR THE MEAN TIME.
                        SAME AS THE VIEW FOR SUBSTITUTE
                        */
                    }





                    <div className="stats__content__metrics__subs__container">
                        <h4 className='stats__content__metrics__subs__header--small'>Starting Lineup</h4>
                        <div className="stats__content__metrics__subs__itemwrap">
                            <div className="stats__content__metrics__label">
                                <img src={props.data.type ? MyTeam :  props.pageData.teamData[props.index].logo} alt="Home Tea Badge" className="stats__content__metrics__label__badge" />
                            </div>
                            <ul className="stats__content__metrics__subs">

                                {
                                    props.data.home_team.lineup.map((item, index) => (
                                        <StartItem
                                            jersey={item.jerseynumber}
                                            name={item.name}
                                            isHome={true}
                                            subbedOff={typeof item.isSubbedOff === 'string' ? !!parseInt(item.isSubbedOff) : item.isSubbedOff}
                                            scored={false}
                                        />
                                    ))
                                }

                                <strong>Formation</strong>
                                <p>4-4-2</p>
                            </ul>
                        </div>

                        <h4 className='stats__content__metrics__subs__header--large'>Starting Lineup</h4>

                        <div className="stats__content__metrics__subs__itemwrap stats__content__metrics__subs__itemwrap--last">
                            <div className="stats__content__metrics__label stats__content__metrics__label--last">
                                <img src={!props.data.type ? MyTeam :  props.pageData.teamData[props.index].logo} alt="Home Tea Badge" className="stats__content__metrics__label__badge" />
                            </div>
                            <ul className="stats__content__metrics__subs stats__content__metrics__subs--last">


                                {
                                    props.data.away_team.lineup.map((item, index) => (
                                        <StartItem
                                            jersey={item.jerseynumber}
                                            name={item.name}
                                            isHome={false}
                                            subbedOff={typeof item.isSubbedOff === 'string' ? !!parseInt(item.isSubbedOff) : item.isSubbedOff}
                                            scored={false}
                                        />
                                    ))
                                }

                                <strong>Formation</strong>
                                <p>4-3-3</p>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="stats__content__metrics">

                    <div className="stats__content__metrics__subs__container">
                    <h4 className='stats__content__metrics__subs__header--small'>Substitues</h4>
                        <div className="stats__content__metrics__subs__itemwrap">
                            <div className="stats__content__metrics__label">
                                <img src={props.data.type ? MyTeam :  props.pageData.teamData[props.index].logo} alt="Home Tea Badge" className="stats__content__metrics__label__badge" />
                            </div>
                            <ul className="stats__content__metrics__subs">

                                {
                                    props.data.home_team.subs.map((item, index) => (
                                        <SubItem
                                            jersey={item.jerseynumber}
                                            name={item.name}
                                            isHome={true}
                                            subbedOn={typeof item.isSubbedOn === 'string' ? !!parseInt(item.isSubbedOn) : item.isSubbedOn}
                                        />
                                    ))

                                }

                            </ul>
                        </div>

                        <h4 className='stats__content__metrics__subs__header--large'>Substitues</h4>


                        <div className="stats__content__metrics__subs__itemwrap stats__content__metrics__subs__itemwrap--last">
                            <div className="stats__content__metrics__label stats__content__metrics__label--last">
                                <img src={!props.data.type ? MyTeam :  props.pageData.teamData[props.index].logo} alt="Home Tea Badge" className="stats__content__metrics__label__badge" />
                            </div>
                            <ul className="stats__content__metrics__subs stats__content__metrics__subs--last">

                                {
                                    props.data.away_team.subs.map((item, index) => (
                                        <SubItem
                                            jersey={item.jerseynumber}
                                            name={item.name}
                                            isHome={false}
                                            subbedOn={typeof item.isSubbedOn === 'string' ? !!parseInt(item.isSubbedOn) : item.isSubbedOn}
                                        />
                                    ))

                                }
                            </ul>
                        </div>

                    </div>
                    <div className="stats__content__metrics__managers">
                        <h4>Managers</h4>
                        <div className="stats__content__metrics__managers__box">
                            <p>{props.data.home_team.manager}</p>
                            <p>{props.data.away_team.manager}</p>
                        </div>
                    </div>

                    <div className="stats__content__metrics__kpis">
                        <h4>Statistics</h4>
                        <div className="stats__content__metrics__kpis__box">
                            <ul className="stats__content__metrics__kpis__values">
                                <li>{props.data.home_team.shots.total}</li>
                                <li>{props.data.home_team.shots.on_target}</li>
                                <li>{`${props.data.home_team.possession}%`}</li>
                                <li>{props.data.home_team.passes.total}</li>
                                <li>{`${props.data.home_team.passes.accuracy}%`}</li>
                                <li>{props.data.home_team.fouls}</li>
                                <li>{props.data.home_team.bookings.filter(x => x.yellow_card).length}</li>
                                <li>{props.data.home_team.bookings.filter(x => !x.yellow_card).length}</li>
                                <li>{props.data.home_team.offsides}</li>
                                <li>{props.data.home_team.shots.corners}</li>
                            </ul>

                            <ul className="stats__content__metrics__kpis__labels">
                                <li>Shots</li>
                                <li>Shots on target</li>
                                <li>Possession</li>
                                <li>Passes</li>
                                <li>Pass Accuracy</li>
                                <li>Fouls</li>
                                <li>Yellow Cards</li>
                                <li>Red Cards</li>
                                <li>Offsides</li>
                                <li>Corners</li>
                            </ul>

                            <ul className="stats__content__metrics__kpis__values">
                                <li>{props.data.away_team.shots.total}</li>
                                <li>{props.data.away_team.shots.on_target}</li>
                                <li>{`${props.data.away_team.possession}%`}</li>
                                <li>{props.data.away_team.passes.total}</li>
                                <li>{`${props.data.away_team.passes.accuracy}%`}</li>
                                <li>{props.data.away_team.fouls}</li>
                                <li>{(props.data.away_team.bookings.filter(x => x.yellow_card)).length}</li>
                                <li>{(props.data.away_team.bookings.filter(x => !x.yellow_card)).length}</li>
                                <li>{props.data.away_team.offsides}</li>
                                <li>{props.data.away_team.shots.corners}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

const SubItem = (props) => {
    return (

        props.isHome ? (
            <li>
                <span className="stats__content__metrics__subs__jersey">{props.jersey}</span>
                <span className="stats__content__metrics__subs__name">{props.name}</span>
                {
                    props.subbedOn && <BsFillArrowUpCircleFill className='stats__content__metrics__subs__icon' />
                }

            </li>
        ) : (
            <li>
                <span className="stats__content__metrics__subs__name">{props.name}</span>
                {
                    props.subbedOn && <BsFillArrowUpCircleFill className='stats__content__metrics__subs__icon' />
                }
                <span className="stats__content__metrics__subs__jersey">{props.jersey}</span>
            </li>
        )


    )
}

// To sort for the pitch lineup show which would be implemented later..

const StartItem = (props) => {
    return (

        props.isHome ? (

            <li>
                <span className="stats__content__metrics__subs__jersey">{props.jersey}</span>
                <span className="stats__content__metrics__subs__name">{props.name}</span>
                {
                    props.subbedOff && <BsFillArrowDownCircleFill className='stats__content__metrics__subs__icon' color='red' />
                }


                {
                    props.scored && <GiSoccerBall className='stats__content__metrics__subs__icon' />
                }
            </li>

        ) : (

            <li>

                <span className="stats__content__metrics__subs__name">{props.name}</span>
                {
                    props.subbedOff && <BsFillArrowDownCircleFill className='stats__content__metrics__subs__icon' color='red' />
                }


                {
                    props.scored && <GiSoccerBall className='stats__content__metrics__subs__icon' />
                }
                <span className="stats__content__metrics__subs__jersey">{props.jersey}</span>
            </li>
        )







    )
}

export default MatchStats