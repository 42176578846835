import React from 'react'
import nowifi from '../../src/img/no-wifi.png';


function NoInternet() {
  return (
    <div className='nointernet'>
      <div className="nointernet__container">
        <img src={nowifi} alt="No internet Representation" />
        <p>Kindly check your internet connection</p>
      </div>
    </div>
  )
}

export default NoInternet