import React from 'react'
import { Header, Footer } from '../shared';
import GalleryMain from './GalleryMain';

function GalleryPage() {
  return (
    <>
    <Header />
    <GalleryMain />
    <Footer />
    </>
  )
}

export default GalleryPage;