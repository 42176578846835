import React, { useEffect, useState, useContext } from 'react'
import { AppDataContext } from '../Contexts/AppDataContext';
import { useLocation, useParams } from 'react-router-dom';
import SinglePlayerLanding from './SinglePlayerLanding'
import SinglePlayerProfile from './SinglePlayerProfile'
import { Header, Footer, Spinner, NoInternet } from '../shared'
import PlayerData from '../shared/PlayerData.json';
import { loadLandingData } from '../shared/loadData';
import _ from 'lodash';

const item = {
  "id": "",
  "fullname": "Loading Loading",
  "role": "",
  "roleadvanced": "",
  "jerseynumber": 0,
  "placeofbirth": "",
  "dateofbirth": "",
  "weight": 0,
  "height": 0,
  "fixturesplayed": "",
  "assists": "",
  "goals": "",
  "minutesplayed": "",
  "imgurl": "",
  "trophies": []
}

function PlayerSingle() {

  const location = useLocation()
  const { pageData, setPageData } = useContext(AppDataContext)
  let { id } = useParams();


  const [data, setData] = useState(item)
  const [randoms, setRandoms] = useState([])
  const [index, setIndex] = useState(0)

  const [appData, setAppData] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const [noWifi, setNoWifi] = useState(false)

  useEffect(() => {
    // console.log('here')

    async function fetchData() {
      const result = await loadLandingData(setAppData, setShowSpinner, setNoWifi, 1)

      const data = result.playerData.find(x => x.id === id)
      const selectedDataIndex = result.playerData.findIndex(x => x.id === id);


      const generatedVals = generateRandom(selectedDataIndex, result.playerData.length)
      setPageData(result)
      setIndex(selectedDataIndex)
      setRandoms(generatedVals)
      setData(data)
    }

    if (_.isEmpty(pageData)) {
      setShowSpinner(true)
      fetchData()
    } else {
      const data = pageData.playerData.find(x => x.id === id)
      const selectedDataIndex = pageData.playerData.findIndex(x => x.id === id);


      const generatedVals = generateRandom(selectedDataIndex, pageData.playerData.length)
      setIndex(selectedDataIndex)
      setRandoms(generatedVals)
      setData(data)
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });

  }, [id])

  return (

    <div className='singleplayer'>
      <Header />

      {
        showSpinner ? <Spinner showSpinner={showSpinner} /> :

          noWifi ? <NoInternet /> :
            <>
              <SinglePlayerLanding
                data={data}
                index={index}
              />
              <SinglePlayerProfile
                data={data}
                index={index}
                randoms={randoms}
              />
            </>
      }
      <Footer />

    </div>
  )
}

export default PlayerSingle

const generateRandom = (index, size) => {
  let randoms = [];
  let randNum;

  while (randoms.length < 3) {
    randNum = Math.floor(Math.random() * size);

    while (randoms.includes(randNum) || randNum === index) {
      // console.log(randNum + '<---- randomNumber  ' + index + '<-------index')
      randNum = Math.floor(Math.random() * size);
    }
    randoms.push(randNum)

  }
  // console.log(randoms)

  return randoms;
}