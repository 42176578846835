import React from 'react'
import SimpleImageSlider from "react-simple-image-slider";
import Jersey1 from '../img/shop/Homefront.png';
import Jersey2 from '../img/shop/Homeback.png';
import Jersey3 from '../img/shop/Awayfront.png';
import Jersey4 from '../img/shop/Awayback.png';
import {isMobile} from 'react-device-detect'

const Images = [Jersey1, Jersey2, Jersey3, Jersey4]

function Shop() {
    return (
        <div className='shop'>
            <div className="shop__intro">
                <button className="shop__intro__headbtn btn">New Arrivals</button>
                <h2>Home & Away Jerseys and Club Accessories for the 2023/24 Season</h2>
                <p>
                    Every true fan should be equipped with fan clothes to show passion for their favorite team.
                    This brand new kit collection features a fun and unique design. Ideal for men or women, to stay stylish while showing off your pride.
                </p>
                <button className="btn shop__btn">
                    Shop Now
                </button>
            </div>

            <div className="shop__slidebox">
                <SimpleImageSlider
                    width={isMobile ? 0.68 * window.innerWidth: 0.4 * window.innerWidth}
                    height={isMobile ? 0.68 * window.innerWidth: 0.4 * window.innerWidth}
                    images={Images}
                    showBullets={true}
                    showNavs={false}
                    autoPlay
                />
            </div>

        </div>
    )
}

export default Shop