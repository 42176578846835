import React from 'react'
import { Header, Footer } from '../shared'
import Item1 from '../img/shop-01-3.png'
import Item2 from '../img/shop-02-3.png';
import Item3 from '../img/shop-03-3.png'
import Item4 from '../img/shop-04-3.png'
import Item5 from '../img/shop-05-3.png'
import item6 from '../img/shop-06-3.png';
import Item7 from '../img/shop-07-3.png'
import Item8 from '../img/shop-08-3.png'
import Item9 from '../img/shop-09-3.png'

function FullShop() {
    return (
        <div className='fullshop'>
            <Header />
            <div className="fullshop__banner">
                <span>Shop</span>
            </div>

            <div className="fullshop__body">
                <ShopItem
                    name={"Home Jersey"}
                    price={"55"}
                >
                    <img src={Item1} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>

                <ShopItem
                    name={"Away Jersey"}
                    price={"52"}
                >
                    <img src={Item2} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>

                <ShopItem
                    name={"Fan Cusotm Jersey"}
                    price={"66"}
                >
                    <img src={Item3} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>

                <ShopItem
                    name={"Full Home  Kit"}
                    price={"102"}
                >
                    <img src={Item4} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>

                <ShopItem
                    name={"Full Away kit"}
                    price={"99"}
                >
                    <img src={Item5} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>

                <ShopItem
                    name={"Full Third Fan Kit"}
                    price={"120"}
                >
                    <img src={item6} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>

                <ShopItem
                    name={"Home Collection"}
                    price={"167"}
                >
                    <img src={Item7} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>

                <ShopItem
                    name={"Away Collection"}
                    price={"154"}
                >
                    <img src={Item8} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>

                <ShopItem
                    name={"Custom Fan Collection"}
                    price={"210"}
                >
                    <img src={Item9} className="fullshop__body__item__img" alt="Shop Item"></img>
                </ShopItem>


            </div>

            <div className="fullshop__btnsection">
                <button className="fullshop__btn btn">View All</button>
            </div>
            <Footer />
        </div>
    )
}

const ShopItem = (props) => {
    return (
        <div className="fullshop__body__item">
            <div className="fullshop__body__item__imgbox">
                {props.children}
            </div>
            <div className="fullshop__body__item__details">
                <p className="fullshop__body__item__details__title">{props.name}</p>
                <p className="fullshop__body__item__details__price">
                    <span>&#8373;</span>
                    <span>{props.price}</span>
                </p>
            </div>
        </div>
    )
}

export default FullShop