import React, { useContext } from 'react'
import _ from 'lodash'
import { AppDataContext } from '../Contexts/AppDataContext'
import Landing from './Landing';
import Matches from './Matches';
import News from './News';
import Players from './Players';
import Shop from './Shop';
import Cards from './Cards';
import { Header, Footer, Spinner } from '../shared'
import { NoInternet } from '../shared';

function AppLanding(props) {

  const { pageData, showSpinner } = useContext(AppDataContext)

  return (
    <>
      <Header />

      {
        showSpinner ? <Spinner showSpinner={showSpinner} /> :
          _.isEmpty(pageData) ? <NoInternet /> :
            <>
              <Landing />
              <Matches data={{teams: pageData.teamData, matches: pageData.matchesData, seasons: pageData.seasons, settings: pageData.settings}} />
              <News data={pageData.newsData} />
              <Players data={pageData.playerData} />
              <Shop />
              <Cards />
            </>
      }

      <Footer />
    </>
  )
}

export default AppLanding