import React from 'react'
import { useNavigate } from 'react-router-dom';
// import NewsData from '../shared/NewsData.json';

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];




function News(props) {

    const navigate = useNavigate()

    return (
        <div className='news'>
            <div className="news__header">
                <div className="news__header__main">
                    <h4>News</h4>
                    <div className="news__header__box">
                        <p>Club</p>
                        {
                            /*
                                <p>Videos</p>
                                <p>Players</p>
                                <p>Transfers</p>
                            */
                        }

                    </div>
                </div>
                <div className="news__header__btnsection">
                    <button 
                        className='news__header__btn btn'
                        onClick={() => navigate('/blog')}
                    >
                        Visit Blog
                    </button>
                </div>
            </div>

            <div className="news__body">

                <div
                    className="news__body__main"
                    onClick={() => navigateToBlog(navigate, 0, props.data.length, props.data)}
                >
                    <div className="news__body__main__imgbox">
                        <img src={props.data[0].imgurl} alt="" className="news__body__main__img" />
                    </div>
                    <div className="news__body__main__textbox">
                        <div className="news__body__main__textbox__head">
                            <h4>News</h4>
                            <span>{`${monthNames[(props.data[0].time_stamp).toDate().getMonth()]} ${(props.data[0].time_stamp).toDate().getDate()}, ${props.data[0].time_stamp.toDate().getFullYear()}`}</span>
                        </div>
                        <div className="news__body__main__textbox__content">
                            {props.data[0].header}
                        </div>
                    </div>
                </div>

                <div className="news__body__sub">


                    {
                        (props.data.filter((item, index) => index !== 0).slice(0, 4)).map((item, index) => (
                            <NewsSubItem
                                data={item}
                                index={index+1}
                                size={props.data.length}
                                allNews={props.data}
                            />
                        ))
                    }

                </div>

            </div>
        </div>
    )
}

export default News

const NewsSubItem = (props) => {

    const navigate = useNavigate()

    return (
        <div
            className="news__body__sub__item"
            onClick={() => navigateToBlog(navigate, props.index, props.size, props.allNews)}
        >
            <div className="news__body__sub__item__imgbox">
                <img src={props.allNews[props.index].imgurl} alt="Blog Visual Representation" className="news__body__sub__item__img" />
            </div>
            <div className="news__body__sub__item__textbox">
                <div className="news__body__sub__item__textbox__head">
                    <h4>News</h4>
                    <span>{`${monthNames[(props.data.time_stamp).toDate().getMonth()]} ${(props.data.time_stamp).toDate().getDate()}, ${props.data.time_stamp.toDate().getFullYear()}`}</span>
                </div>
                <div className="news__body__sub__item__textbox__content">
                    {props.data.header}
                </div>
            </div>
        </div>
    )
}

const navigateToBlog = (navigate, index, size, allNews) => {

    navigate(`/readblog/${allNews[index].id}`)
}